import { Button, Modal } from "react-bootstrap";

const ModalConfirmacao = (props: any, {show, onHide, onSubmitHandler, titulo, variant, confirmText, cancelText, disabledConfirm}: any) => {
    return (            
        <Modal centered show={props.show} onHide={() => props.onHide(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {props.titulo}
                </Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
                {props.children}
            </Modal.Body>

            <Modal.Footer style={props.variant === 'none' ? {display: 'none'} : {}}>
                <Button id="ModalBtnCancel" variant="secondary" onClick={() => props.onHide(false)}>
                    {props.cancelText === undefined ? 'Fechar' : props.cancelText}
                </Button>

                <Button id="ModalBtnOk" disabled={props.disabledConfirm} variant={props.variant} onClick={props.onSubmitHandler}>
                    {props.confirmText === undefined ? 'Confirmar' : props.confirmText}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalConfirmacao;