import { useGlobalFilter, useSortBy, useTable, usePagination } from 'react-table';
import { Pagination, Table } from 'react-bootstrap';
import { useMemo } from 'react';

interface Props {
    colunas         : any;
    dados           : Array<object>;
    handleClick?    : (prop: any) => void;
};
const empty : any = [];

const Tabela = ({colunas, dados, handleClick} : Props)  => {
    const columns = useMemo(() => colunas, [colunas]);
    const data = useMemo(() => dados || empty, [dados]);
    
    const { 
        getTableProps,
        getTableBodyProps, 
        headerGroups, 
        // rows, 
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        prepareRow, 
        pageOptions,
        gotoPage,
        pageCount,
        //setPageSize,
        state
    } = useTable(
        { columns, data, initialState: { pageIndex : 0, pageSize : 20 } },
        useGlobalFilter, 
        useSortBy, 
        usePagination
    );
    const { pageIndex } = state;

    return(
        <>
            <Table striped bordered hover className='tabela' {...getTableProps()}>
                <thead>{headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps(/*column.getSortByToggleProps()*/)}>
                                { column.render("Header") }
                                { /*column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""*/ }
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, index) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} onClick={() => !!handleClick ? handleClick(row.original) : null}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>{ cell.render("Cell") }</td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </Table>

            {
                dados.length > 15 ?
                    <Pagination>
                        <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                        <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
                        {pageIndex > 0 ? <Pagination.Item onClick={() => gotoPage(0)}>{1}</Pagination.Item> : null}
                        {pageIndex === 2 ? <Pagination.Item onClick={() => gotoPage(1)}>{pageIndex}</Pagination.Item> : null}
                        {pageIndex > 3 ? <Pagination.Ellipsis /> : null}
                        {pageIndex > 2 ? <><Pagination.Item onClick={() => gotoPage(pageIndex - 2)}>{pageIndex - 1}</Pagination.Item> <Pagination.Item onClick={() => gotoPage(pageIndex - 1)}>{pageIndex}</Pagination.Item></> : null}   
                        
                        <Pagination.Item active>{pageIndex + 1}</Pagination.Item> 

                        {pageIndex + 1 < pageOptions.length - 1 ? <Pagination.Item onClick={() => gotoPage(pageIndex + 1)}>{pageIndex + 2}</Pagination.Item> : null}
                        {pageIndex + 2 < pageOptions.length - 1 ? <Pagination.Item onClick={() => gotoPage(pageIndex + 2)}>{pageIndex + 3}</Pagination.Item> : null}
                        {pageIndex + 1 < pageOptions.length - 1 ? <Pagination.Ellipsis /> : null}
                        {pageIndex + 1 !== pageOptions.length ? <Pagination.Item onClick={() => gotoPage(pageCount - 1)}>{pageOptions.length}</Pagination.Item> : null}
                        <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
                        <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
                    </Pagination>
                :
                    <></>
            }

            {/* Quantidade de registros filtrados: {dados.length} */}
        </>
    );
}

export default Tabela;