const useMask = () => {
    const getUnmasked = (value: string) : string => {
        if (typeof value !== 'undefined') {
            value = value.replace(/\D/g, '');
        };

        return value;
    }

    const setMoney = (value: number, coin: string = "", decimalSeparattor: string = ",") => {
        const thousandSeparator = decimalSeparattor === "," ? "." : ",";

  // Formata o número com separador de milhares e decimal
        const formattedValue = parseFloat(value.toString())
            .toFixed(2)
            .replace('.', decimalSeparattor)
            .replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);

        // Retorna o valor formatado com ou sem o símbolo de moeda
        return `${coin !== "" ? coin + " " : ""}${formattedValue}`;
    };

    const getNormalized = (str: string) : string => {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    const setPhone = (phone: any) => {
        if (typeof phone !== 'undefined') {
            phone = phone.replace(/\D/g, '');

            if (phone.slice(0, 1) !== '(' && phone.slice(0, 1).length !== 0) {
            phone = '(' + phone;
            }
            if (phone.slice(3, 4) !== ')' && phone.slice(3, 4).length !== 0) {
            phone = phone.slice(0, 3) + ') ' + phone.slice(3);
            }
            if (phone.slice(10, 11).length !== 0 && phone.slice(10, 11) !== '-') {
            phone = phone.slice(0, 10) + '-' + phone.slice(10);
            }
            if (phone.length > 9) {
            phone = phone.slice(0, 15);
            }
        }
        
        return phone;
    };

    const setCep = (cep: string) : string => {
        if (typeof cep !== 'undefined' && cep !== "" && cep !== null) {
            cep = cep.replace(/\D/g, '');
            cep = cep.replace(/^(\d{5})(\d)/,"$1-$2");
        };

        return cep;
    };

    const getCurrentDate = (separator: string = '') => {
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        
        return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
    };

    const setDate = (date: Date | null, format: Intl.DateTimeFormatOptions | undefined, useUtc: boolean = true, yearFirst: boolean = false) : string => {
        if (date !== null) {
            const formato : Intl.DateTimeFormatOptions = useUtc ? {...format, timeZone: 'UTC'} : {...format};

            if (yearFirst) {
                const dataFormatada = date.toLocaleString('pt-BR', formato).replace(/\//g, '-').replace(/,/g, '');
                return dataFormatada.split(' ')[0].split('-').reverse().join('-') + ' ' + dataFormatada.split(' ')[1];
            } else {
                return date.toLocaleString("pt-BR", formato);
            }            
        } else {
            return ""
        }
    };

    const setOverflow = (value: string, limit: number = 20) : string => value.length > limit ? `${value.substring(0, limit)}...` : value;

    const getCapitalized = (value: string) => {
        value = value + '';
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }

    const getMinMax = (Min: number, Max: number, Value: number): number => {
        if (Value <= Min) {
            return Min
        } else if (Value >= Max) {
            return Max
        } else {
            return Value
        }
    }

    const setZeros = (Mask: string, Precision: number, Value: string|number) => {
        return (Mask + Value).slice(Precision)
    }

    return {
        getUnmasked,
        getCurrDate: getCurrentDate,
        getPhone: getUnmasked,
        getCep: getUnmasked,
        setDate: setDate,
        setMaskPhone: setPhone,
        setMaskCep: setCep,
        setMaskMoney: setMoney,
        setOverflow: setOverflow,
        getCapitalized: getCapitalized,
        getNormalized: getNormalized,
        getMinMax,
        setZeros
    };
}

export default useMask;