import { Outlet, useParams } from "react-router-dom";
import useJogo from "./Hook/useJogo";

const Jogo = () => {
    const { cdJogo } = useParams();
    const Jogo = useJogo(cdJogo);

    return (
        <Outlet context={Jogo} />
    );
}

export default Jogo;