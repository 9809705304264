import { useOutletContext } from "react-router-dom";
import { Button, Container, Form} from "react-bootstrap";
import FormRodape from "../../Components/formularios/FormRodape";
import { Formik } from "formik";
import { useState } from "react";
import { EMode } from "../../Interfaces";
import BadgeValidacao from "../../Components/BadgeValidacao";
import { every } from "lodash";
import { ITipoJogo, schemaTipoJogo } from "../../Interfaces/TipoJogo";
import { IUseTipoJogo } from "./Hook/useTipoJogo";

const TipoJogoCadastro = () => {
    const TipoJogo = useOutletContext<IUseTipoJogo>();
    const [confirmado, setConfirmado] = useState<boolean>(false);

    return (
        <Container className="verticalCenter">
            <Formik
                enableReinitialize
                validationSchema={schemaTipoJogo}
                onSubmit={(dados : ITipoJogo) => {
                    if (TipoJogo.Modo === EMode.Edit) {
                        TipoJogo.Controller.Alterar.mutateAsync(dados, {
                            onSuccess: () => {
                                TipoJogo.Navegar('0');
                            }
                        });
                    } else if (TipoJogo.Modo === EMode.Include) {
                        TipoJogo.Controller.Cadastrar.mutateAsync(dados, {
                            onSuccess: () => {
                                TipoJogo.Navegar('0');
                            }
                        });
                    }
                }}
                initialValues={{
                    CD_TIPO_JOGO    : TipoJogo.Datasource.Item.CD_TIPO_JOGO     ?? undefined,
                    NOME            : TipoJogo.Datasource.Item.NOME             ?? "",
                    QTD_MIN         : TipoJogo.Datasource.Item.QTD_MIN          ?? "",
                    QTD_MAX         : TipoJogo.Datasource.Item.QTD_MAX          ?? "",
                    VLR_MIN         : TipoJogo.Datasource.Item.VLR_MIN          ?? "",
                    VLR_MAX         : TipoJogo.Datasource.Item.VLR_MAX          ?? "",
                    QTD_MIN_CONCURSO: TipoJogo.Datasource.Item.QTD_MIN_CONCURSO ?? "",
                    QTD_MAX_CONCURSO: TipoJogo.Datasource.Item.QTD_MAX_CONCURSO ?? ""
                }}
                >
                {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    errors,
                }) => (
                    <Form onSubmit={handleSubmit} onReset={() => TipoJogo.Navegar('0')}>       
                        <Form.Group className="mt-3">
                            <Form.Label>Nome do Tipo de Jogo:</Form.Label>
                            <BadgeValidacao validacao={errors.NOME} confirmado={confirmado} />
                            <Form.Control 
                                name={'NOME'}
                                type={'text'}
                                value={values.NOME ?? ""}
                                onChange={handleChange}
                                isInvalid={every([errors.NOME, confirmado])}
                                placeholder="Nome identificador do tipo de jogo..." 
                                size="lg"
                            />
                        </Form.Group> 

                        <Form.Group className="mt-3">
                            <Form.Label>Quantidade Mínima:</Form.Label>
                            <BadgeValidacao validacao={errors.QTD_MIN} confirmado={confirmado} />
                            <Form.Control 
                                name={'QTD_MIN'}
                                type={'number'}
                                disabled={TipoJogo.Modo === EMode.Edit}
                                value={values.QTD_MIN ?? ""}
                                onChange={handleChange}
                                min={0}
                                max={998}
                                isInvalid={every([errors.QTD_MIN, confirmado])}
                                placeholder="Mín. Dezenas Selecionaveis..." 
                                size="lg"
                            />
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Form.Label>Quantidade Máxima:</Form.Label>
                            <BadgeValidacao validacao={errors.QTD_MAX} confirmado={confirmado} />
                            <Form.Control 
                                name={'QTD_MAX'}
                                type={'number'}
                                disabled={TipoJogo.Modo === EMode.Edit}
                                value={values.QTD_MAX ?? ""}
                                onChange={handleChange}
                                min={0}
                                max={999}
                                isInvalid={every([errors.QTD_MAX, confirmado])}
                                placeholder="Máx. Dezenas Selecionaveis..." 
                                size="lg"
                            />
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Form.Label>Valor Mínimo:</Form.Label>
                            <BadgeValidacao validacao={errors.VLR_MIN} confirmado={confirmado} />
                            <Form.Control 
                                name={'VLR_MIN'}
                                type={'number'}
                                disabled={TipoJogo.Modo === EMode.Edit}
                                value={values.VLR_MIN ?? ""}
                                onChange={handleChange}
                                min={0}
                                max={998}
                                isInvalid={every([errors.VLR_MIN, confirmado])}
                                placeholder="Valor Mín. Intervalo Dezenas..." 
                                size="lg"
                            />
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Form.Label>Valor Máximo:</Form.Label>
                            <BadgeValidacao validacao={errors.VLR_MAX} confirmado={confirmado} />
                            <Form.Control 
                                name={'VLR_MAX'}
                                type={'number'}
                                disabled={TipoJogo.Modo === EMode.Edit}
                                value={values.VLR_MAX ?? ""}
                                onChange={handleChange}
                                min={0}
                                max={999}
                                isInvalid={every([errors.VLR_MAX, confirmado])}
                                placeholder="Valor Máx. Intervalo Dezenas..." 
                                size="lg"
                            />
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Form.Label>Quantidade Mínima (Concurso):</Form.Label>
                            <BadgeValidacao validacao={errors.QTD_MIN_CONCURSO} confirmado={confirmado} />
                            <Form.Control 
                                name={'QTD_MIN_CONCURSO'}
                                type={'number'}
                                disabled={TipoJogo.Modo === EMode.Edit}
                                value={values.QTD_MIN_CONCURSO ?? ""}
                                onChange={handleChange}
                                min={0}
                                max={998}
                                isInvalid={every([errors.QTD_MIN_CONCURSO, confirmado])}
                                placeholder="Mín. Dezenas Selecionaveis p/ Concurso..." 
                                size="lg"
                            />
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Form.Label>Quantidade Máxima (Concurso):</Form.Label>
                            <BadgeValidacao validacao={errors.QTD_MAX_CONCURSO} confirmado={confirmado} />
                            <Form.Control 
                                name={'QTD_MAX_CONCURSO'}
                                type={'number'}
                                disabled={TipoJogo.Modo === EMode.Edit}
                                value={values.QTD_MAX_CONCURSO ?? ""}
                                onChange={handleChange}
                                min={0}
                                max={999}
                                isInvalid={every([errors.QTD_MAX_CONCURSO, confirmado])}
                                placeholder="Máx. Dezenas Selecionaveis p/ Concurso..." 
                                size="lg"
                            />
                        </Form.Group>

                        <FormRodape>
                            <Button variant="outline-danger" size="lg" onClick={() => {}} type="reset">Cancelar</Button>
                            <Button variant="success" size="lg" onClick={() => {setConfirmado(true)}} type="submit">Confirmar</Button>
                        </FormRodape>
                    </Form>
                )}
            </Formik>            
        </Container>
    )
}

export default TipoJogoCadastro;