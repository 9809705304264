import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from "react-query";
import useAPI from "../Components/useAPI";
import { DeepPartial, IErro } from "../Interfaces";
import useControllers from "../Components/useControllers";
import { IConcurso } from "../Interfaces/Concurso";

interface Props {
    updateQueryKey  : string;
    isArray?        : boolean;
    queryValue?     : string;
    enableUsuario?  : boolean;
    enableQuery?    : boolean;
}

const useConcursoController = ({updateQueryKey, queryValue, isArray = true, enableUsuario = false, enableQuery = false} : Props) : IUseConcursoController => {
    const API = useAPI();
    const queryClient = useQueryClient();
    const { Update, Create, Delete } = useControllers();

    const primaryKey = 'CD_CONCURSO';
    const jogoKey = 'CD_JOGO'; // eslint-disable-line
    const endpoint = 'concurso';

    const Listar = useQuery<IConcurso[]>(
        updateQueryKey!,
        (
            () => API.get(`${endpoint}/listar`, '', false) // Todos concursos
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 3,
            enabled: enableQuery, // Só será habilitado pelo enableQuery
            refetchInterval: 300000,
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
        }
    ); 

    const ListarUsuario = useQuery<IConcurso[]>(
        updateQueryKey!,
        (
            () => API.get(`${endpoint}/listarUsuario`, '', false) // Todos concursos
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 3,
            enabled: false,
            refetchInterval: 300000,
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
        }
    ); 

    const ListarJogo = useQuery<IConcurso[]>(
        updateQueryKey!+'Jogo',
        (
            () => API.get(`${endpoint}/listarJogo/${queryValue}`, '', false) // Apostas do jogo
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 3,
            enabled: !!queryValue, // Só será habilitado se passar um cd_jogo
            refetchInterval: 300000,
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
        }
    );  

    const Alterar = useMutation(
        (data : DeepPartial<IConcurso>) => 
            API.put(`${endpoint}/alterar/${data[primaryKey]}`, data)
        , {
            onSuccess: (ret: DeepPartial<IConcurso>, data: DeepPartial<IConcurso>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IConcurso[]>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Update(oldData, data, primaryKey, isArray);
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
    });

    const Processar = useMutation(
        (data : DeepPartial<IConcurso>) => 
            API.put(`${endpoint}/processar/${data[primaryKey]}`, {})
        , {
            onSuccess: (ret: DeepPartial<IConcurso>, data: DeepPartial<IConcurso>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IConcurso[]>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Update(oldData, data, primaryKey, isArray);
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
    });

    const Deletar = useMutation(
        (data : DeepPartial<IConcurso>) => 
            API.del(`${endpoint}/deletar/${data[primaryKey]}`, data, true)
        , {
            onSuccess: (ret: DeepPartial<IConcurso>, data: DeepPartial<IConcurso>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IConcurso[]>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Delete(oldData, {...data});
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
    });

    const Cadastrar = useMutation(
        (data : DeepPartial<IConcurso>) => 
            API.post(`${endpoint}/cadastrar`, data)
        , {
            onSuccess: (ret: any, data: DeepPartial<IConcurso>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IConcurso[]>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Create(oldData, ret);
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
    });

    const Buscar = useMutation(
        (data : DeepPartial<IConcurso>) => 
            API.get(`${endpoint}/buscar/${data[primaryKey]}`, "")
        , {
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
    });

    return {
        Alterar     : Alterar,
        Processar   : Processar,
        Cadastrar   : Cadastrar,
        Deletar     : Deletar,
        Buscar      : Buscar,
        ListarUsuario      : ListarUsuario,
        ListarJogo  : ListarJogo,
        Listar      : Listar
    }
}

export interface IUseConcursoController {
    Alterar         : UseMutationResult<DeepPartial<IConcurso>, any, DeepPartial<IConcurso>, unknown>;
    Processar       : UseMutationResult<DeepPartial<IConcurso>, any, DeepPartial<IConcurso>, unknown>;
    Cadastrar       : UseMutationResult<DeepPartial<IConcurso>, any, DeepPartial<IConcurso>, unknown>
    Deletar         : UseMutationResult<DeepPartial<IConcurso>, any, DeepPartial<IConcurso>, unknown>;
    Buscar          : UseMutationResult<IConcurso, any, DeepPartial<IConcurso>, unknown>;
    ListarUsuario   : UseQueryResult<IConcurso[], unknown>;
    ListarJogo      : UseQueryResult<IConcurso[], unknown>;
    Listar          : UseQueryResult<IConcurso[], unknown>;
}

export default useConcursoController;