import { useOutletContext } from "react-router-dom";
import { IUseJogo } from "../Hook/useJogo";
import useMask from "../../../Components/useMask";
import Tabela from "../../../Components/tabela";
import { IJogoComissao } from "../../../Interfaces/Jogo";
import { Badge, Container, Form } from "react-bootstrap";

const JogoPremioInfo = () => {
    const Jogo = useOutletContext<IUseJogo>();
    const QtdAposta = (Jogo.Datasource.Item.QTD_APOSTAS ?? 0);
    const VlrAposta = (Jogo.Datasource.Item.VLR_APOSTA ?? 0);
    const TotAposta = QtdAposta * VlrAposta;
    const formatter = useMask();

    return (
        <Container className="verticalCenter">
            <div className="mt-3 w-100">
                <Tabela
                    dados={Jogo.Datasource.Item.COMISSAO?.filter(comissao => comissao.TIPO !== 'CI') ?? []} 
                    colunas={[
                        {
                            Header: 'Prêmio',
                            id: "nome",
                            Cell: (row: any) : any => row.row.original['NOME']
                        },  
                        {
                            Header: 'Valor Total',
                            id: "valortot",
                            Cell: (row: any) : any => {
                                const comissao : IJogoComissao = row.row.original;
                                const Percentual = (Number(comissao.PCE_PERCENTUAL ?? 0) / 100);
                                return (
                                    <div className="d-flex w-100 justify-content-center"><h5><Badge pill bg="light" text="success">{formatter.setMaskMoney(TotAposta * Percentual, 'R$')}</Badge></h5></div>                            
                                )
                            }
                        },
                        {
                            Header: 'Odd Máx.',
                            id: "odd",
                            Cell: (row: any) : any => {
                                const comissao : IJogoComissao = row.row.original;
                                const Percentual = (Number(comissao.PCE_PERCENTUAL ?? 0) / 100);
                                return (
                                    <div className="d-flex w-100 justify-content-center"><Badge pill bg="success">{Number((TotAposta * Percentual) / VlrAposta).toFixed(2)}x</Badge></div>                            
                                )
                            }
                        },
                    ]}
                />
            </div>
            <div className="mt-3 w-100">
                <Form.Group className="mt-3">
                    <Form.Label>Informações gerais:</Form.Label>
                    <Form.Control 
                        name={'OBSERVACAO'}
                        type={'text'}
                        as={'textarea'}
                        disabled
                        value={Jogo.Datasource.Item.OBSERVACAO ?? ""}
                    />
                </Form.Group>     
            </div>
        </Container>
    )
}

export default JogoPremioInfo;