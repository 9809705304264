import * as yup from 'yup';
import { NOME, DT_TEMPO } from './schemas';

export interface IConcurso {
    CD_CONCURSO?        : number;
    NM_CONCURSO?        : string;
    CD_TIPO_JOGO?       : number;
    CD_PESSOA_CADASTRO? : number;    
    STATUS              : EConcursoStatus;
    DT_CONCURSO?        : string | Date;
    DT_CADASTRO?        : string | Date;
    LANCAMENTO?         : number[];
    JOGO?               : number[];
};

export enum EConcursoStatus {
    Rascunho    = 'RA',
    Finalizado  = 'FN'
}

export const schemaConcurso = yup.object().shape({   
    NM_CONCURSO     : NOME,
    DT_CONCURSO     : DT_TEMPO,
    CD_TIPO_JOGO    : yup.number().required('Deve ser preenchido')
});