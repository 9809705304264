import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from "react-query";
import useAPI from "../Components/useAPI";
import { DeepPartial, IErro } from "../Interfaces";
import useControllers from "../Components/useControllers";
import { IJogo } from "../Interfaces/Jogo";

interface Props {
    updateQueryKey  : string;
    isArray?        : boolean;
    enableQuery?    : boolean;
    enableJogaveis? : boolean;
}

const useJogoController = ({updateQueryKey, isArray = true, enableQuery = true, enableJogaveis = false} : Props) : IUseJogoController => {
    const API = useAPI();
    const queryClient = useQueryClient();
    const { Update, Create, Delete } = useControllers();

    const primaryKey = 'CD_JOGO';
    const endpoint = 'jogo';

    const Listar = useQuery<IJogo[]>(
        updateQueryKey!,
        (
            () => API.get(`${endpoint}/listar`, '', false)
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 3,
            enabled: enableQuery,
            refetchInterval: 300000,
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
        }
    );  

    const ListarJogaveis = useQuery<IJogo[]>(
        updateQueryKey!+'Jogaveis',
        (
            () => API.get(`${endpoint}/listarJogaveis`, '', false)
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 3,
            enabled: enableJogaveis,
            refetchInterval: 300000,
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
        }
    );  

    const Alterar = useMutation(
        (data : DeepPartial<IJogo>) => 
            API.put(`${endpoint}/alterar/${data[primaryKey]}`, data)
        , {
            onSuccess: (ret: DeepPartial<IJogo>, data: DeepPartial<IJogo>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IJogo[]>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Update(oldData, data, primaryKey, isArray);
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
    });

    const Deletar = useMutation(
        (data : DeepPartial<IJogo>) => 
            API.del(`${endpoint}/deletar/${data[primaryKey]}`, data, true)
        , {
            onSuccess: (ret: DeepPartial<IJogo>, data: DeepPartial<IJogo>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IJogo[]>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Delete(oldData, {...data});
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
    });

    const Cadastrar = useMutation(
        (data : DeepPartial<IJogo>) => 
            API.post(`${endpoint}/cadastrar`, data)
        , {
            onSuccess: (ret: any, data: DeepPartial<IJogo>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IJogo[]>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Create(oldData, ret);
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
    });

    const Buscar = useMutation(
        (data : DeepPartial<IJogo>) => 
            API.get(`${endpoint}/buscar/${data[primaryKey]}`, "")
        , {
            onSuccess: (ret: IJogo) => {
                // setItem(ret);
            },
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
    });

    return {
        Alterar     : Alterar,
        Cadastrar   : Cadastrar,
        Listar      : Listar,
        Deletar     : Deletar,
        Buscar      : Buscar,
        ListarJogaveis : ListarJogaveis
    }
}

export interface IUseJogoController {
    Alterar     : UseMutationResult<DeepPartial<IJogo>, any, DeepPartial<IJogo>, unknown>;
    Cadastrar   : UseMutationResult<DeepPartial<IJogo>, any, DeepPartial<IJogo>, unknown>
    Listar      : UseQueryResult<IJogo[], unknown>;
    Deletar     : UseMutationResult<DeepPartial<IJogo>, any, DeepPartial<IJogo>, unknown>;
    Buscar      : UseMutationResult<IJogo, any, DeepPartial<IJogo>, unknown>;
    ListarJogaveis: UseQueryResult<IJogo[], unknown>;
}

export default useJogoController;