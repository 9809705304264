import { useMemo } from "react";
import { IReactTable_Grid } from "../../../../Interfaces";
import { Button } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { ITipoJogo } from "../../../../Interfaces/TipoJogo";
import { IUseJogoController } from "../../../../Controllers/Jogo";

interface Props {
    Controller : IUseJogoController;
}

const useJogoGrid = ({Controller} : Props) : IUseJogoGrid => {
    const Lista = useMemo<IReactTable_Grid>(() => [
        {
            id: "CD_JOGO",
            Header: "Jogo",
            accessor: "CD_JOGO"
        },  
        {
            id: "NOME",
            Header: "Nome",
            accessor: "NOME"
        },    
        {
            id: "BtnExcluir",
            Header: "",
            Cell: (row: any) : any => 
                <div className="w-100 d-flex justify-content-around">
                    <Button 
                        variant="danger"
                        onClick={(e: any) => {
                            e.stopPropagation();
                            const selecionado : ITipoJogo = row.row.original;
                            if (window.confirm(`Deseja excluir o jogo ${selecionado.NOME}?`)) {
                                Controller.Deletar.mutate(selecionado);
                            }
                        }}
                    >
                        <FaTrashAlt />
                    </Button>
                </div>
        },
    ], [Controller.Deletar]);

    return {
        Lista   : Lista
    }
}

export interface IUseJogoGrid {
    Lista   : IReactTable_Grid;
}

export default useJogoGrid;