import { useEffect, useMemo } from "react";
import { ICtxAppParams } from "../Contexts/ctxAppParams";
import usePessoaController from "../Controllers/Pessoa";

const usePermission = (AppParams: ICtxAppParams) => {
    const { Acessos } = usePessoaController('Pessoa', true, false);

	const PessoaAcesso = useMemo(() => {
        if (Acessos.isFetched && Acessos.isSuccess) {
            return Acessos.data
        } else {
            return null
        }
    }, [Acessos]);

    useEffect(() => {
        AppParams.setPermissoes(PessoaAcesso);
    }, [PessoaAcesso, AppParams]);
}

export default usePermission;