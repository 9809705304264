import { useMemo } from "react";
import { IUseConcursoController } from "../../../../Controllers/Concurso";
import { IConcurso } from "../../../../Interfaces/Concurso";


interface Props {
    Controller: IUseConcursoController;
}

const useConcursoDatasource = ({Controller} : Props) : IUseConcursoDatasource => {
    const ListaJogo = useMemo<IConcurso[]>(() => {
            if (Controller.ListarJogo.isFetched && Controller.ListarJogo.isSuccess && Controller.ListarJogo.data) {
                return Controller.ListarJogo.data.sort((a, b) => (b.CD_CONCURSO ?? 0) - (a.CD_CONCURSO ?? 0));
            } else {
                return [{} as IConcurso];
            };
    }, [Controller.ListarJogo.data]); //eslint-disable-line

    const ListaUsuario = useMemo<IConcurso[]>(() => {
        if (Controller.ListarUsuario.isFetched && Controller.ListarUsuario.isSuccess && Controller.ListarUsuario.data) {
            return Controller.ListarUsuario.data.sort((a, b) => (b.CD_CONCURSO ?? 0) - (a.CD_CONCURSO ?? 0));
        } else {
            return [{} as IConcurso];
        };
    }, [Controller.ListarUsuario.data]); //eslint-disable-line

    const Lista = useMemo<IConcurso[]>(() => {
        if (Controller.Listar.isFetched && Controller.Listar.isSuccess && Controller.Listar.data) {
            return Controller.Listar.data.sort((a, b) => (b.CD_CONCURSO ?? 0) - (a.CD_CONCURSO ?? 0));
        } else {
            return [{} as IConcurso];
        };
    }, [Controller.Listar.data]); //eslint-disable-line

    const Item = useMemo<IConcurso>(() => {
        if (Controller.Buscar.isSuccess && Controller.Buscar.data) {
            return Controller.Buscar.data;
        } else {
            return {} as IConcurso;
        };
    }, [Controller.Buscar.isSuccess, Controller.Buscar.data]);

    return {
        ListaJogo,
        ListaUsuario,
        Lista,
        Item
    }
}

export interface IUseConcursoDatasource {
    ListaJogo           : IConcurso[];
    ListaUsuario        : IConcurso[];
    Lista               : IConcurso[];
    Item                : IConcurso;
}

export default useConcursoDatasource;