import { useEffect, useState } from "react";

const useStateStorage = <T,>(identifier: string, defaultValue: T) => {
    const [value, setValue] = useState<T>(() => {
        try {
            const savedValue = localStorage.getItem(identifier + '_StateStorage');
            return savedValue !== null ? JSON.parse(savedValue) : defaultValue;
        } catch (error) {
            console.error('localStorage reading error:', error);
            return defaultValue;
        }
    });

    useEffect(() => {
        try {
            localStorage.setItem(identifier + '_StateStorage', JSON.stringify(value));
        } catch (error) {
            console.error('localStorage sabing error:', error);
        }
    }, [identifier, value]);

    return {
        Read: value,
        Write: setValue
    }
}

export default useStateStorage;