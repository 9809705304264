import moment from 'moment';
import * as Yup from 'yup';

export const PACIENTE = Yup.string()
    .min(3, 'Nome deve ser válido')
    .max(120, 'Nome está muito longo!')
    .required('Nome precisa ser preenchido')

export const NUMERO = Yup.number()
    .min(0, 'Deve ser válido')
    .required('Deve ser preenchido')

export const PRIORIDADE = Yup.number()
    .min(0, 'Deve ser maior que zero')
    .max(127, 'Deve ser menor que 127')
    .required('Deve ser preenchido')    

export const NOME = Yup.string()
    .min(3, 'Deve ser válido')
    .max(120, 'Muito longo!')
    .required('Deve ser preenchido')  

export const CONVENIO = Yup.string()
    .required('Deve ser selecionado')    

export const PENDENCIA = Yup.string()
    .required('Deve ser preenchido')    

export const DT_TEMPO = Yup.mixed()
    .required('Deve ser preenchido')
    .test('is-datetime', 'Data e hora deve ser válida', (value) => moment(value, "YYYY-MM-DD HH:mm:ss", true).isValid())

export const DT_ENTRADA = Yup.mixed()
    .required('Deve ser preenchido')
    .test('is-date', 'Data deve ser válida', (value) => moment(value, "YYYY-MM-DD", true).isValid())

export const DT_SAIDA = Yup.mixed()
    .required('Deve ser preenchido')
    .when('DT_ENTRADA', (DT_ENTRADA, schema) => {
        return schema.test('is-date', 'Data deve ser válida', function (value) {
            const { DT_ENTRADA } = this.parent;
            return moment(DT_ENTRADA, "YYYY-MM-DD", true).isValid() && moment(value, "YYYY-MM-DD", true).isValid() && (DT_ENTRADA <= value);
        })
    })
    .nullable()