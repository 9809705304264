import { useOutletContext } from "react-router-dom";
import { IUseJogo } from "../Hook/useJogo";
import Tabela from "../../../Components/tabela";
import { Badge, Container } from "react-bootstrap";
import { ILancamento } from "../../../Interfaces/Aposta";
import { useMemo } from "react";
import { EStatus } from "../../../Interfaces/Jogo";

const JogoResultado = () => {
    const Jogo = useOutletContext<IUseJogo>();

    const maxDigits = useMemo(() => {
        return Jogo.TipoJogo?.VLR_MAX.toString().length;
    }, [Jogo.TipoJogo?.VLR_MAX]);

    const colunasAposta = useMemo(() => [
        {
            Header: 'Nome',
            id: "nome",
            Cell: (row: any) : any => row.row.original['NOME']
        },
        {
            Header: 'Aposta',
            id: "aposta",
            Cell: (row: any) : any => {
                const Lancamentos : ILancamento[] = row.row.original['LANCAMENTO'];
                
                return Lancamentos && Lancamentos.map(lancamento => {
                    return (
                        <Badge 
                            pill 
                            className="mx-1"
                            style={{fontSize: '1rem'}}
                            bg={lancamento.ACERTO ? 'primary' : 'light'}
                            text={lancamento.ACERTO ? 'light' : 'dark'}
                        >
                            {lancamento.NUMERO.toString().padStart(maxDigits, '0')}
                        </Badge>
                    )
                })
            }
        },
        {
            Header: 'Pontuação',
            id: "pontuacao",
            Cell: (row: any) : any => {
                return (
                    <Badge 
                        style={{fontSize: '1rem'}}
                        bg={row.row.original['PONTO'] === Jogo.TipoJogo?.QTD_MAX ? 'success' : 'dark'}
                        text={row.row.original['PONTO'] === Jogo.TipoJogo?.QTD_MAX ? 'light' : 'light'}
                    >
                        {row.row.original['PONTO']}
                    </Badge>
                )
            }
        },
    ], [maxDigits, Jogo.TipoJogo?.QTD_MAX]);

    const colunasConcurso = useMemo(() => [
        {
            Header: 'Concurso',
            id: "concurso",
            Cell: (row: any) : any => row.row.original['NM_CONCURSO']
        },
        {
            Header: 'Lançamentos',
            id: "lancamentos",
            Cell: (row: any) : any => {
                const Lancamentos : number[] = row.row.original['LANCAMENTO'];
                
                return Lancamentos && Lancamentos.map(lancamento => {
                    return (
                        <Badge 
                            pill 
                            className="mx-1"
                            style={{fontSize: '1rem'}}
                            bg={'primary'}
                        >
                            {lancamento.toString().padStart(maxDigits, '0')}
                        </Badge>
                    )
                })
            }
        },
        {
            Header: 'Data',
            id: "data",
            Cell: (row: any) : any => row.row.original['DT_CONCURSO']
        }
    ], [maxDigits]);    

    return (
        <Container className="verticalCenter">
            {
                [EStatus.Aberto, EStatus.Rascunho].includes(Jogo.Datasource.Item.STATUS) &&
                    <div className="mt-3">
                        O Jogo ainda não começou. Os Resultados serão exibidos assim que for iniciado.
                    </div>
                
            }         
            {
                Jogo.Aposta.Datasource.ListaJogo && Jogo.Aposta.Datasource.ListaJogo.length > 0 &&
                <div className="mt-3 w-100">
                    <Tabela
                        dados={Jogo.Aposta.Datasource.ListaJogo} 
                        colunas={colunasAposta}
                    />
                </div>
            }
            {
                Jogo.Concurso.Datasource.ListaJogo && Jogo.Concurso.Datasource.ListaJogo.length > 0 &&
                <div className="mt-3 w-100">
                    <Tabela
                        dados={Jogo.Concurso.Datasource.ListaJogo} 
                        colunas={colunasConcurso}
                    />
                </div>
            }            
        </Container>
    )
}

export default JogoResultado;