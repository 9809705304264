import { useOutletContext } from "react-router-dom";
import { IUseControle } from "./Hook/useControle";
import { Button, Card, Container, Form, Nav, Tab } from "react-bootstrap";
import FormRodape from "../../Components/formularios/FormRodape";
import { Formik } from "formik";
import { useState } from "react";
import { EMode } from "../../Interfaces";
import { IControle, IRegra, schemaControle } from "../../Interfaces/Controle";
import BadgeValidacao from "../../Components/BadgeValidacao";
import { every } from "lodash";
import Tabela from "../../Components/tabela";
import { IPessoa } from "../../Interfaces/Pessoa";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import useMask from "../../Components/useMask";
import usePessoaController from "../../Controllers/Pessoa";
import useRegraController from "../../Controllers/Regra";

const ControleCadastro = () => {
    const Controle = useOutletContext<IUseControle>();
    const [confirmado, setConfirmado] = useState<boolean>(false);
    const [aba, setAba] = useState<'r' |'p'>('r');
    const formatter = useMask();
    const { Buscar } = usePessoaController('Pessoa', true, false);
    const RegraController = useRegraController({updateQueryKey: 'Regra', enableQuery: Controle.Modo === EMode.Include});

    return (
        <Container className="verticalCenter">
            <Formik
                enableReinitialize
                validationSchema={schemaControle}
                onSubmit={(dados : IControle) => {
                    if (Controle.Modo === EMode.Edit) {
                        Controle.Controller.Alterar.mutateAsync(dados, {
                            onSuccess: () => {
                                Controle.Navegar('0');
                            }
                        });
                    } else if (Controle.Modo === EMode.Include) {
                        Controle.Controller.Cadastrar.mutateAsync(dados, {
                            onSuccess: () => {
                                Controle.Navegar('0');
                            }
                        });
                    }
                }}
                initialValues={{
                    CD_CONTROLE : Controle.Datasource.Item.CD_CONTROLE  ?? undefined,
                    NOME        : Controle.Datasource.Item.NOME         ?? Controle.Datasource.Item.CONTROLE ?? '',
                    PRIORIDADE  : Controle.Datasource.Item.PRIORIDADE   ?? 0,
                    REGRAS      : Controle.Datasource.Item.REGRAS       ?? RegraController.Listar.data ?? [],
                    PESSOAS     : Controle.Datasource.Item.PESSOAS      ?? []
                }}
                >
                {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    errors,
                }) => (
                    <Form onSubmit={handleSubmit} onReset={() => Controle.Navegar('0')}>       
                        <Form.Group className="mt-3">
                            <Form.Label>Nome do Controle:</Form.Label>
                            <BadgeValidacao validacao={errors.NOME} confirmado={confirmado} />
                            <Form.Control 
                                name={'NOME'}
                                type={'text'}
                                value={values.NOME ?? ""}
                                onChange={handleChange}
                                isInvalid={every([errors.NOME, confirmado])}
                                placeholder="Nome identificador do controle..." 
                                size="lg"
                            />
                        </Form.Group> 

                        <Form.Group className="mt-3">
                            <Form.Label>Prioridade:</Form.Label>
                            <BadgeValidacao validacao={errors.PRIORIDADE} confirmado={confirmado} />
                            <Form.Control 
                                name={'PRIORIDADE'}
                                type={'number'}
                                value={values.PRIORIDADE ?? ""}
                                onChange={handleChange}
                                min={0}
                                max={127}
                                isInvalid={every([errors.PRIORIDADE, confirmado])}
                                placeholder="Prioridade..." 
                                size="lg"
                            />
                        </Form.Group>

                        <Tab.Container
                            defaultActiveKey={(() => {
                                switch(aba) {
                                    case 'r':
                                        return 'r'
                                    case 'p':
                                        return 'p'
                                    default:
                                        return 'r'
                                }})()
                            }
                            onSelect={(eventKey) => setAba(eventKey as any)} 
                        >
                            <Card className="card mt-3">
                                {/* className={styles.cardForm}> */}
                                <Card.Header>
                                    <Nav variant="tabs">
                                        <Nav.Item>
                                            <Nav.Link eventKey="r">Regras</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="p">Pessoas</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Card.Header>

                                <Card.Body className="d-flex flex-wrap justify-content-center">
                                    {
                                        aba === 'r' ?
                                            <Tabela dados={values.REGRAS ?? []} colunas={[
                                                {
                                                    Header: 'Descrição',
                                                    id: "regra",
                                                    Cell: (row: any) : any => row.row.original['REGRA'] ?? row.row.original['IDENTIFICACAO']
                                                },
                                                {
                                                    Header: 'Valor',
                                                    id: "valor",
                                                    Cell: (row: any) : any => {
                                                        const selecionado : IRegra = row.row.original;
        
                                                        return <Form.Control 
                                                            id={'valor'+selecionado.CD_REGRA}
                                                            name={'valor'+selecionado.CD_REGRA}
                                                            type={'text'}
                                                            value={selecionado.VALOR}
                                                            onChange={(e) => {
                                                                const atualizaRegra = values.REGRAS?.map((regra: IRegra) => regra.CD_REGRA === selecionado.CD_REGRA ? {...regra, VALOR: e.target.value} : regra);
                                                                setFieldValue("REGRAS", atualizaRegra);
                                                            }}
                                                        />
                                                    }
                                                },
                                                {
                                                    Header: 'Permissão',
                                                    id: "permissao",
                                                    Cell: (row: any) : any => {
                                                        const selecionado : IRegra = row.row.original;
        
                                                        return <Form.Check
                                                            name={selecionado.NOME}
                                                            type={'checkbox'}
                                                            checked={selecionado.ACESSO}
                                                            onChange={() => {
                                                                const atualizaRegra = values.REGRAS?.map((regra: IRegra) => regra.CD_REGRA === selecionado.CD_REGRA ? {...regra, ACESSO: !regra.ACESSO} : regra)
                                                                setFieldValue("REGRAS", atualizaRegra);
                                                            }}
                                                            label={selecionado.ACESSO ? 'Permitido' : 'Negado'}
                                                        />
                                                    }
                                                }        
                                            ]}
                                            />
                                        :
                                            <Tabela dados={values.PESSOAS ?? []} colunas={[
                                                {
                                                    Header: 'Nome',
                                                    id: "nome",
                                                    accessor: "NOME"
                                                },
                                                {
                                                    Header: 'Celular',
                                                    id: "celular",
                                                    Cell: (row: any) : any => formatter.setMaskPhone(row.row.original['CELULAR'])
                                                },
                                                {
                                                    id: "BtnExcluir",
                                                    Header: (row: any) : any => <div className="w-100 d-flex justify-content-around">
                                                        <Button 
                                                            variant="success"
                                                            onClick={(e: any) => {
                                                                e.stopPropagation();
                                                                const celularInformado = window.prompt(`Digite o celular da pessoa que deseja adicionar ao controle ${values.NOME}`);
                                                                if (celularInformado?.length === 11) {
                                                                    Buscar.mutateAsync({CELULAR: celularInformado}, {
                                                                        onSuccess: (ret: IPessoa) => {
                                                                            if (window.confirm(`Deseja adicionar a pessoa: ${ret.NOME} \n Celular: ${formatter.setMaskPhone(ret.CELULAR)} \n ao controle ${values.NOME}?`)) {
                                                                                const adicionarPessoa = [...values.PESSOAS!, ret]
                                                                                setFieldValue("PESSOAS", adicionarPessoa);
                                                                            }
                                                                        }
                                                                    })
                                                                } else {
                                                                    alert('Celular informado inválido.');
                                                                }
                                                            }}
                                                        >
                                                            <FaPlus />
                                                        </Button>
                                                    </div>,
                                                    Cell: (row: any) : any => 
                                                        <div className="w-100 d-flex justify-content-around">
                                                            <Button 
                                                                variant="danger"
                                                                onClick={(e: any) => {
                                                                    e.stopPropagation();
                                                                    const selecionado : IPessoa = row.row.original;
                                                                    if (window.confirm(`Deseja remover a pessoa: ${selecionado.NOME} \n Celular: ${formatter.setMaskPhone(selecionado.CELULAR)} \n do controle ${values.NOME}?`)) {
                                                                        const excluirPessoa = values.PESSOAS?.filter(pessoa => pessoa.CD_PESSOA !== selecionado.CD_PESSOA);
                                                                        setFieldValue("PESSOAS", excluirPessoa);
                                                                    }
                                                                }}
                                                            >
                                                                <FaTrashAlt />
                                                            </Button>
                                                        </div>
                                                },
                                            ]}
                                            />
                                    }
                                    
                                </Card.Body>
                            </Card>
                        </Tab.Container>

                        <FormRodape>
                            <Button variant="outline-danger" size="lg" onClick={() => {}} type="reset">Cancelar</Button>
                            <Button variant="success" size="lg" onClick={() => {setConfirmado(true)}} type="submit">Confirmar</Button>
                        </FormRodape>
                    </Form>
                )}
            </Formik>            
        </Container>
    )
}

export default ControleCadastro;