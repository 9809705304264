import { useOutletContext } from "react-router-dom"
import { IUseControle } from "./Hook/useControle";
import Tabela from "../../Components/tabela";
import { Button, Container } from "react-bootstrap";
import FormRodape from "../../Components/formularios/FormRodape";
import { TiPlus } from "react-icons/ti";
import { IControle } from "../../Interfaces/Controle";

const ControleLista = () => {
    const Controle = useOutletContext<IUseControle>();

    return (
        <Container className="verticalCenter">
            <Tabela colunas={Controle.Grid.Lista} dados={Controle.Datasource.Lista} handleClick={(e : IControle) => Controle.Navegar(String(e.CD_CONTROLE))} />

            <FormRodape>
                <Button variant="success" size="lg" onClick={() => Controle.Navegar('-1')}><TiPlus /> Cadastrar</Button>
            </FormRodape>
        </Container>        
    )
}

export default ControleLista;