import { useEffect, useMemo, useState } from "react";
import { EStatus } from "../../Interfaces/Jogo";
import useMask from "../useMask";

interface Props {
    DT_INICIO: string;
    STATUS: EStatus;
}

const Countdown = ({DT_INICIO, STATUS} : Props) => {
    const getTimeRemaining = (endDate: string) => {
        const total = Date.parse(endDate) - Date.now();
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        const days = Math.floor(total / (1000 * 60 * 60 * 24));
        return {
            total, days, hours, minutes, seconds
        };
    };
    
    const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining(DT_INICIO));
    const {setDate} = useMask();
    
    useEffect(() => {
        const timer = setInterval(() => {
            setTimeRemaining(getTimeRemaining(DT_INICIO));
        }, 1000);
    
        return () => clearInterval(timer); // Limpar o intervalo ao desmontar o componente
    }, [DT_INICIO]);

    const tempo = useMemo(() => {
        if (timeRemaining.days > 0) {
            return (
                `${timeRemaining.days}d ${timeRemaining.hours}h ${timeRemaining.minutes}m ${timeRemaining.seconds}s`
            )
        } else if (timeRemaining.hours > 0) {
            return (
                `${timeRemaining.hours}h ${timeRemaining.minutes}m ${timeRemaining.seconds}s`
            )
        } else if (timeRemaining.minutes > 0) {
            return (
                `${timeRemaining.minutes}m ${timeRemaining.seconds}s`
            )
        } else {
            return (
                `${timeRemaining.seconds}s`
            )
        }
    }, [timeRemaining]);

    if (timeRemaining.total <= 0) {
        if (STATUS === EStatus.Aberto) {
            return <span>Vendas encerram hoje!</span>;
        } else {
            return <span>{`Iniciou em: ${setDate(new Date(DT_INICIO), {dateStyle: "medium"}, false)}`}</span>;
        }
    }

    return (
        <span>
            {`Vendas abertas até: ${tempo}`}
        </span>
    );
}

export default Countdown;