import { Outlet, useParams } from "react-router-dom";
import useControle from "./Hook/useControle";

const Controle = () => {
    const { cdControle } = useParams();
    const Controle = useControle(cdControle);

    return (
        <Outlet context={Controle} />
    );
}

export default Controle;