import { useMemo } from "react";
import { IReactTable_Grid } from "../../../Interfaces";
import { Button } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { IUseTipoJogoController } from "../../../Controllers/TipoJogo";
import { ITipoJogo } from "../../../Interfaces/TipoJogo";

interface Props {
    Controller : IUseTipoJogoController;
}

const useTipoJogoGrid = ({Controller} : Props) : IUseTipoJogoGrid => {
    const Lista = useMemo<IReactTable_Grid>(() => [
        {
            id: "CD_TIPO_JOGO",
            Header: "Tipo",
            accessor: "CD_TIPO_JOGO"
        },  
        {
            id: "NOME",
            Header: "Nome",
            accessor: "NOME"
        },    
        {
            id: "BtnExcluir",
            Header: "",
            Cell: (row: any) : any => 
                <div className="w-100 d-flex justify-content-around">
                    <Button 
                        variant="danger"
                        onClick={(e: any) => {
                            e.stopPropagation();
                            const selecionado : ITipoJogo = row.row.original;
                            if (window.confirm(`Deseja excluir o tipo de jogo ${selecionado.NOME}?`)) {
                                Controller.Deletar.mutate(selecionado);
                            }
                        }}
                    >
                        <FaTrashAlt />
                    </Button>
                </div>
        },
    ], [Controller.Deletar]);

    return {
        Lista   : Lista
    }
}

export interface IUseTipoJogoGrid {
    Lista   : IReactTable_Grid;
}

export default useTipoJogoGrid;