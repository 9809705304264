import { useOutletContext } from "react-router-dom"
import { Button, Col, Container } from "react-bootstrap";
import FormRodape from "../../Components/formularios/FormRodape";
import { TiPlus } from "react-icons/ti";
import { IUseJogo } from "./Hook/useJogo";
import JogoCard from "../../Components/jogo/jogoCard";
import { v4 as uuidv4 } from "uuid";

const JogoLista = () => {
    const Jogo = useOutletContext<IUseJogo>();

    return (
        <Container className="verticalCenter">
            
            {
                Jogo.Datasource.Lista.map(Item => {
                    return (
                        <Col key={uuidv4()} lg={3}>
                            <JogoCard key={uuidv4()} Jogo={Item} onClick={ () => Jogo.Navegar(String(Item.CD_JOGO)) } />
                        </Col>
                    )
                })
            }

            {
                Jogo.AlteraJogo ?   
                    <FormRodape>
                        <Button variant="success" size="lg" onClick={() => Jogo.Navegar('-1')}><TiPlus /> Cadastrar</Button>
                    </FormRodape>
                : 
                    <></>
            }            
        </Container>        
    )
}

export default JogoLista;