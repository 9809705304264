import { useContext } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { MdExitToApp } from "react-icons/md";
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import { Gestor } from "./gestor";
import useApp from "../useApp";
import usePagina from "../usePagina";
import { useNavigate } from "react-router-dom";
import { FaDice } from "react-icons/fa";
import { PiHandWithdrawFill } from "react-icons/pi";

const Header = () => {
    const AppParams = useContext(CtxAppParams);
    const PaginaHook = usePagina();
    const PaginaJogo = PaginaHook.RetornaPagina('Jogo');
    const PaginaAposta = PaginaHook.RetornaPagina('Aposta');
    const navigate = useNavigate();
    const {Deslogar} = useApp();

    return (
        <>
            {[false].map((expand: any) => (
                <Navbar key={expand} bg="dark" variant="dark" expand={expand} collapseOnSelect className="mb-3" sticky="top">
                    <Container fluid>
                        <Navbar.Brand>
                            {AppParams.PaginaAtual?.Description}
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />

                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title style={{fontSize: '1.8rem'}} id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    Menu
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                    
                            <Offcanvas.Body>
                                {
                                    AppParams.Token ?
                                        <Nav className="justify-content-end flex-grow-1 pe-3" style={{fontSize: '1.5rem'}}>
                                            {
                                                PaginaJogo && <Nav.Link 
                                                    href="#"
                                                    onClick={() => navigate(PaginaJogo.Path)} 
                                                    className="d-flex align-items-center justify-content-start"
                                                >
                                                    <FaDice style={{fontSize: '1.5rem', width: '3rem'}} />
                                                    {PaginaJogo.Description}
                                                </Nav.Link>
                                            }           
                                            {
                                                PaginaAposta && <Nav.Link 
                                                    href="#"
                                                    onClick={() => navigate(PaginaAposta.Path)} 
                                                    className="d-flex align-items-center justify-content-start"
                                                >
                                                    <PiHandWithdrawFill style={{fontSize: '2.2rem', width: '3rem'}} />
                                                    {PaginaAposta.Description}
                                                </Nav.Link>
                                            }                                  
                                            <Gestor HookPagina={PaginaHook} />
                                            <h1><hr /></h1>
                                            <Nav.Link 
                                                className="d-flex align-items-center justify-content-start"
                                                href="/"
                                                onClick={() => Deslogar()}
                                            >
                                                <MdExitToApp style={{fontSize: '1.9rem', width: '3rem'}} />
                                                Sair
                                            </Nav.Link>
                                        </Nav>
                                    :
                                        <></>
                                }
                                
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
        </>
    );
};

export default Header;