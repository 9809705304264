import { useMemo } from "react";
import { EstadosCidades } from "../Constants/estados-cidades";

const useCidadeEstado = (UF: string) => {
    const Estados = useMemo(() => {
        return (
            EstadosCidades.estados.map(uf => uf.sigla)
        )
    }, []);

    const Cidades = useMemo(() => {
        if (Estados.includes(UF)) {
            return EstadosCidades.estados.filter(uf => uf.sigla === UF).map(cidades => cidades.cidades)[0]
            
        } else {
            return []
        }
    }, [Estados, UF]);

    return {
        Estados,
        Cidades
    }
}

export interface IUseCidadeEstado {
    Estados: string[];
    Cidades: string[];
}

export default useCidadeEstado;