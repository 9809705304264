import { createContext } from 'react';
import { IPessoaAcesso } from '../Interfaces/Pessoa';
import { IPagina } from '../Constants/paginas';

export interface ICtxAppParams {
    SessaoExpirada: boolean;
    setSessaoExpirada: React.Dispatch<React.SetStateAction<boolean>>;

    Token: string | null | undefined;
    setToken: React.Dispatch<React.SetStateAction<string | null | undefined>>;

    Permissoes?: IPessoaAcesso[] | null;
    setPermissoes: React.Dispatch<React.SetStateAction<IPessoaAcesso[] | null | undefined>>;

    PaginaAtual?: IPagina | null;
    setPaginaAtual: React.Dispatch<React.SetStateAction<IPagina | null | undefined>>;
}

export const CtxAppParams = createContext<ICtxAppParams>({
    SessaoExpirada: false,
    Token: undefined,
    Permissoes: undefined,
    PaginaAtual: undefined
} as ICtxAppParams);