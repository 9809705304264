import { useFormikContext } from "formik";
import { ETipo, IJogo, IJogoComissao } from "../../../Interfaces/Jogo";
import Tabela from "../../../Components/tabela";
import useMask from "../../../Components/useMask";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import ModalConfirmacao from "../../../Components/ModalConfirmacao";
import { useState } from "react";
import { JogoComissaoTipo } from "../../../Constants/jogo";

const JogoCadastroComissao = () => {
    const { setFieldValue, values } = useFormikContext<IJogo>();
    const formatter = useMask();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [jogoComissao, setJogoComissao] = useState<IJogoComissao>({TIPO: 'CI'} as IJogoComissao);

    return (
        <>
            <ModalConfirmacao
                titulo={'Adicionar Comissão'}
                show={showModal} 
                onHide={setShowModal}
                variant={"primary"}
                onSubmitHandler={() => {
                    if (jogoComissao.NOME?.trim() === '') {
                        alert('Descrição da comissão deve ser preenchida');
                    } else if (!jogoComissao.PCE_PERCENTUAL) {
                        alert('Percentual deve ser preenchido');
                    }
                    
                    const adicionarComissao = [...values.COMISSAO!, jogoComissao]
                    setFieldValue("COMISSAO", adicionarComissao);
                    setJogoComissao({TIPO: 'CI'} as IJogoComissao);
                    setShowModal(false);
                }}
            >
                <Row>
                    <Col md={12}>
                        <Form.Group>
                            <Form.Label>Descrição:</Form.Label>
                            <Form.Control 
                                autoComplete="off"
                                name={'JOGOCOMISSAO_NOME'}
                                type={'text'}
                                value={jogoComissao.NOME ?? ""}
                                onChange={(e) => setJogoComissao({...jogoComissao, NOME: e.target.value})}
                                placeholder="Descrição da comissão ou prêmio..." 
                                size="lg"
                            />
                        </Form.Group> 
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <Form.Group className="mt-3">
                            <Form.Label>Tipo:</Form.Label>
                            <Form.Select 
                                name={'JOGOCOMISSAO_TIPO'}
                                value={jogoComissao.TIPO}
                                onChange={e => {
                                    setJogoComissao({...jogoComissao, TIPO: e.target.value as ETipo})
                                }}
                                size="lg"
                            >
                                {JogoComissaoTipo.map((Item) => (
                                    <option key={Item.TIPO} value={Item.TIPO}>
                                        {Item.DESCRICAO}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group> 
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mt-3">
                            <Form.Label>Percentual:</Form.Label>
                            <InputGroup>
                                <InputGroup.Text id="percentual">%</InputGroup.Text>
                                <Form.Control 
                                    name={'JOGOCOMISSAO_PCE_PERCENTUAL'}
                                    type={'number'}
                                    min={0}
                                    step={0.5}
                                    max={100}
                                    value={jogoComissao.PCE_PERCENTUAL ?? ""}
                                    onChange={(e) => setJogoComissao({...jogoComissao, PCE_PERCENTUAL: Number(e.target.value)})}
                                    size="lg"
                                />
                            </InputGroup>
                        </Form.Group> 
                    </Col>
                </Row>
            </ModalConfirmacao>

            <Tabela 
                dados={values.COMISSAO ?? []} 
                colunas={[
                    {
                        Header: 'Tipo',
                        id: "tipo",
                        accessor: "TIPO"
                    },
                    {
                        Header: 'Descrição',
                        id: "nome",
                        Cell: (row: any) : any => row.row.original['NOME']
                    },  
                    {
                        Header: 'Percentual',
                        id: "percentual",
                        Cell: (row: any) : any => formatter.setMaskMoney(row.row.original['PCE_PERCENTUAL'], '') + '%'
                    },
                    {
                        id: "BtnExcluir",
                        Header: (row: any) : any => <div className="w-100 d-flex justify-content-around">
                            <Button 
                                variant="success"
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    setShowModal(true);
                                }}
                            >
                                <FaPlus />
                            </Button>
                        </div>,
                        Cell: (row: any) : any => 
                            <div className="w-100 d-flex justify-content-around">
                                <Button 
                                    variant="danger"
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        const selecionado : IJogoComissao = row.row.original;
                                        if (window.confirm(`Deseja remover a comissão: ${selecionado.NOME}?`)) {
                                            const excluirComissao = values.COMISSAO?.filter(comissao => !((comissao.TIPO === selecionado.TIPO) && (comissao.NOME === selecionado.NOME) && (comissao.PCE_PERCENTUAL === selecionado.PCE_PERCENTUAL)));
                                            setFieldValue("COMISSAO", excluirComissao);
                                        }
                                    }}
                                >
                                    <FaTrashAlt />
                                </Button>
                            </div>
                    },
                ]}
            />
        </>
    )
}

export default JogoCadastroComissao;