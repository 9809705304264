import { Button } from "react-bootstrap";
import useColorScheme from "./colorScheme";
import Dezena from "./dezena";
import { FaBirthdayCake, FaEraser, FaPlus } from "react-icons/fa";

interface Props extends React.HTMLProps<HTMLDivElement> {
    VlrMin: number;
    VlrMax: number;
    QtdMin: number;
    QtdMax: number;
    Selecionado?: number[] | null;
    setSelecionado: React.Dispatch<React.SetStateAction<number[]>>;
    Carrinho?: number[][] | null;
    setCarrinho?: React.Dispatch<React.SetStateAction<Array<number[]> | undefined>>;
    Modo?: 'Browse' | 'Edit';
}

const CartaoDezena = ({VlrMin, VlrMax, QtdMin, QtdMax, Selecionado, setSelecionado, Carrinho, setCarrinho, Modo = 'Edit', ...rest}: Props) => {
    const Colors = useColorScheme();

    const maxDigits = VlrMax.toString().length;
    const formattedNumbers = Array.from(
        { length: VlrMax - VlrMin + 1 },
        (_, i) => (VlrMin + i).toString().padStart(maxDigits, '0')
    );

    const handleClick = (number: number) => {
        setSelecionado((prevSelecionado) => {
            if (prevSelecionado?.includes(number)) {
                return prevSelecionado!.filter(n => n !== number);
            } else if (prevSelecionado!.length < QtdMax) {
                return [...prevSelecionado!, number];
            } else {
                return prevSelecionado;
            }
        });
    };

    const handleClear = () => {
        setSelecionado([]);
    };

    const handleGenerate = () => {
        const numeros = new Set<number>();
        while (numeros.size < QtdMax) {
            const numeroAleatorio = Math.floor(Math.random() * (VlrMax - VlrMin + 1)) + VlrMin;
            numeros.add(numeroAleatorio);
        }
        setSelecionado(Array.from(numeros));
    };

    const handleAdd = () => {
        if (Selecionado && Selecionado!.length === QtdMax) {
            const Numeros = [...Selecionado].sort((a, b) => a - b);
            setCarrinho!((prev) => {
                if (prev && prev.length > 0) {
                    if (prev.some(aposta => JSON.stringify(aposta) === JSON.stringify(Numeros))) {
                        alert('Aposta já foi inserida no carrinho!');
                        return prev;
                    } else {
                        return [Numeros, ...prev]
                    }
                } else {
                    return [Numeros]
                }
            });

            handleClear();
        } else {
            alert(`Você precisa selecionar ${QtdMax} números para confirmar a aposta!`);
        }
    };

    return (
        <div className="d-flex flex-wrap justify-content-start" {...rest}>
            {
                Modo === 'Edit' ?
                    formattedNumbers.map((numberStr) => {
                        const number = parseInt(numberStr, 10);
                        const isSelected = Selecionado?.includes(number);
                        const color = isSelected ? Colors.Primary : Colors.Gray;

                        return (
                            <Dezena
                                key={number}
                                Color={color}
                                Number={numberStr}
                                Scale={3}
                                onClick={() => handleClick(number)}
                            />
                        );
                    })
                :
                    Selecionado!.map((numero) => {
                        return (
                            <Dezena
                                key={numero}
                                Color={Colors.Primary}
                                Number={numero.toString().padStart(maxDigits, '0')}
                                Scale={3}
                            />
                        );
                    })
            }
            {
                Carrinho && <div className="d-flex w-100 justify-content-around me-3 mt-3">
                    <Button variant="danger" onClick={handleClear}><FaEraser /> Limpar</Button>
                    <Button variant="warning" onClick={handleGenerate}><FaBirthdayCake /> Surpresinha</Button>
                    <Button variant="primary" onClick={handleAdd}><FaPlus /> Adicionar</Button>
                </div>
            }
        </div>
    )
}

export default CartaoDezena;