import { NOME } from "./schemas";
import * as Yup from 'yup';

export interface ITipoJogo {
    CD_TIPO_JOGO?   : number;
    NOME            : string;
    QTD_MIN         : number;
    QTD_MAX         : number;
    VLR_MIN         : number;
    VLR_MAX         : number;
    QTD_MIN_CONCURSO: number;
    QTD_MAX_CONCURSO: number;
};

export const schemaTipoJogo = Yup.object().shape({   
    NOME            : NOME,
    QTD_MIN         : Yup.number().min(1, 'Deve ser maior que zero').max(998, 'Deve ser menor que 998').required('Deve ser preenchido'),
    QTD_MAX         : Yup.number().min(Yup.ref('QTD_MIN'), 'Deve ser maior ou igual que quantidade minima').max(999, 'Deve ser menor que 999').required('Deve ser preenchido'),
    VLR_MIN         : Yup.number().min(0, 'Deve ser maior ou igual zero').max(998, 'Deve ser menor que 998').required('Deve ser preenchido'),
    VLR_MAX         : Yup.number().max(999, 'Deve ser menor que 999').required('Deve ser preenchido').test('is-greater', 'Deve ser maior que quantidade mínima', function(value) {
        const { QTD_MIN } = this.parent;
        return value > QTD_MIN;
    }),
    QTD_MIN_CONCURSO: Yup.number().min(1, 'Deve ser maior que zero').max(998, 'Deve ser menor que 998').required('Deve ser preenchido'),
    QTD_MAX_CONCURSO: Yup.number().min(Yup.ref('QTD_MIN_CONCURSO'), 'Deve ser maior ou igual que quantidade minima').max(999, 'Deve ser menor que 999').required('Deve ser preenchido'),
});