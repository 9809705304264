import { useMemo } from "react";
import { IUseControleController } from "../../../Controllers/Controle";
import { IControle } from "../../../Interfaces/Controle";

interface Props {
    Controller: IUseControleController;
}

const useControleDatasource = ({Controller} : Props) : IUseControleDatasource => {
    const Lista = useMemo<IControle[]>(() => {
        if (Controller.Listar.isFetched && Controller.Listar.isSuccess && Controller.Listar.data) {
            return Controller.Listar.data;
        } else {
            return [{} as IControle];
        };
    }, [Controller.Listar]);

    const Item = useMemo<IControle>(() => {
        if (Controller.Buscar.isSuccess && Controller.Buscar.data) {
            return Controller.Buscar.data;
        } else {
            return {} as IControle;
        };
    }, [Controller.Buscar.isSuccess, Controller.Buscar.data]);

    return {
        Lista,
        Item
    }
}

export interface IUseControleDatasource {
    Lista   : IControle[];
    Item    : IControle;
}

export default useControleDatasource;