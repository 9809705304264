import { Accordion, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { RiUserStarFill } from "react-icons/ri";
import { IUsePagina } from "../usePagina";
import { VscTypeHierarchy } from "react-icons/vsc";
import { FaVoteYea } from "react-icons/fa";

interface Props {
    HookPagina  : IUsePagina;
};

export const Gestor = ({HookPagina}: Props) => {
    const navigate = useNavigate();
    const PaginaTipoJogo = HookPagina.RetornaPagina('TipoJogo');
    const PaginaControle = HookPagina.RetornaPagina('Controle');
    const PaginaConcurso = HookPagina.RetornaPagina('Concurso');

    if ([PaginaConcurso, PaginaControle, PaginaTipoJogo].some(pagina => pagina !== undefined)) {
        return (
            <Accordion className="mt-3">
                <Accordion.Item eventKey="menuGestao">
                    <Accordion.Header>Gestão</Accordion.Header>
                    <Accordion.Body>
                        {
                            PaginaConcurso && <Nav.Link 
                                href="#"
                                onClick={() => navigate(PaginaConcurso.Path)} 
                                className="d-flex align-items-center justify-content-start"
                            >
                                <FaVoteYea style={{fontSize: '1.6rem', width: '3rem'}} />
                                {PaginaConcurso.Description}
                            </Nav.Link>
                        }
                        {
                            PaginaTipoJogo && <Nav.Link 
                                href="#"
                                onClick={() => navigate(PaginaTipoJogo.Path)} 
                                className="d-flex align-items-center justify-content-start"
                            >
                                <VscTypeHierarchy style={{fontSize: '1.9rem', width: '3rem'}} />
                                {PaginaTipoJogo.Description}
                            </Nav.Link>
                        }
                        {
                            PaginaControle && <Nav.Link 
                                href="#"
                                onClick={() => navigate(PaginaControle.Path)} 
                                className="d-flex align-items-center justify-content-start"
                            >
                                <RiUserStarFill style={{fontSize: '1.9rem', width: '3rem'}} />
                                {PaginaControle.Description}
                            </Nav.Link>
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        );
    } else {
        return (
            <></>
        )
    }
};