import { useMemo } from "react";
import { IUseJogoController } from "../../../../Controllers/Jogo";
import { IJogo } from "../../../../Interfaces/Jogo";


interface Props {
    Controller: IUseJogoController;
    AlteraJogo: boolean;
}

const useJogoDatasource = ({Controller, AlteraJogo} : Props) : IUseJogoDatasource => {
    const Lista = useMemo<IJogo[]>(() => {
        if (AlteraJogo) {
            if (Controller.Listar.isFetched && Controller.Listar.isSuccess && Controller.Listar.data) {
                return Controller.Listar.data.sort((a, b) => {
                    return (b.CD_JOGO ?? 0) - (a.CD_JOGO ?? 0);
                });
            } else {
                return [{} as IJogo];
            };
        } else {
            if (Controller.ListarJogaveis.isFetched && Controller.ListarJogaveis.isSuccess && Controller.ListarJogaveis.data) {
                return Controller.ListarJogaveis.data.sort((a, b) => {
                    return (b.CD_JOGO ?? 0) - (a.CD_JOGO ?? 0);
                });
            } else {
                return [{} as IJogo];
            };
        }
    }, [Controller.Listar, Controller.ListarJogaveis, AlteraJogo]);

    const Item = useMemo<IJogo>(() => {
        if (Controller.Buscar.isSuccess && Controller.Buscar.data) {
            const sortedData = { ...Controller.Buscar.data };
            if (sortedData.COMISSAO) {
                sortedData.COMISSAO = [...sortedData.COMISSAO].sort((a, b) => {
                    return b.PCE_PERCENTUAL - a.PCE_PERCENTUAL;
                });
            };

            return sortedData;
        } else {
            return {} as IJogo;
        };
    }, [Controller.Buscar.isSuccess, Controller.Buscar.data]);

    return {
        Lista,
        Item
    }
}

export interface IUseJogoDatasource {
    Lista   : IJogo[];
    Item    : IJogo;
}

export default useJogoDatasource;