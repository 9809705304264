import { Card } from "react-bootstrap"
import { IAposta } from "../../Interfaces/Aposta";
import { IUseColorScheme } from "../aposta/colorScheme";
import Dezena from "../aposta/dezena";

interface Props extends React.HTMLProps<HTMLDivElement> {
    Colors: IUseColorScheme;
    MaxDigits: number;
    Aposta: IAposta;
}

const CartaoPuleAposta = ({Aposta, Colors, MaxDigits, ...rest}: Props) => {
    return (
            <Card className="mt-3" bg='light' border="light">
                <Card.Header>
                    {`Aposta #${Aposta.CD_JOGO_PULE_APOSTA}`}
                </Card.Header>
                <Card.Body className="d-flex justify-content-center flex-wrap">
                    {
                        Aposta.LANCAMENTO && Aposta.LANCAMENTO.map(lancamento => {
                            return <Dezena
                                key={lancamento.NUMERO}
                                Number={lancamento.NUMERO.toString().padStart(MaxDigits, '0')} 
                                Color={lancamento.ACERTO ? Colors.Primary : Colors.Gray}
                                Scale={2}
                            />
                        })
                    }
                </Card.Body>
            </Card>
    )
}

export default CartaoPuleAposta;