import { Outlet } from "react-router-dom";
import useAposta from "./Hook/useAposta";

const Aposta = () => {
    const Aposta = useAposta();

    return (
        <Outlet context={Aposta} />
    );
}

export default Aposta;