import { useMemo } from "react";
import { IReactTable_Grid } from "../../../Interfaces";
import { Button } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { IControle } from "../../../Interfaces/Controle";
import { IUseControleController } from "../../../Controllers/Controle";

interface Props {
    Controller : IUseControleController
}

const useControleGrid = ({Controller} : Props) : IUseControleGrid => {
    const Lista = useMemo<IReactTable_Grid>(() => [
        {
            id: "CD_CONTROLE",
            Header: "Controle",
            accessor: "CD_CONTROLE"
        },  
        {
            id: "CONTROLE",
            Header: "Nome",
            accessor: "CONTROLE"
        },   
        {
            id: "PRIORIDADE",
            Header: "Prioridade",
            accessor: "PRIORIDADE"
        },   
        {
            id: "BtnExcluir",
            Header: "",
            Cell: (row: any) : any => 
                <div className="w-100 d-flex justify-content-around">
                    <Button 
                        variant="danger"
                        onClick={(e: any) => {
                            e.stopPropagation();
                            const selecionado : IControle = row.row.original;
                            if (window.confirm(`Deseja excluir o controle ${selecionado.CONTROLE}?`)) {
                                Controller.Deletar.mutate(selecionado);
                            }
                        }}
                    >
                        <FaTrashAlt />
                    </Button>
                </div>
        },
    ], [Controller.Deletar]);

    return {
        Lista   : Lista
    }
}

export interface IUseControleGrid {
    Lista   : IReactTable_Grid;
}

export default useControleGrid;