import { useOutletContext } from "react-router-dom";
import { IUseAposta } from "./Hook/useAposta";
import { Col, Container, Spinner } from "react-bootstrap";
import CartaoPule from "../../Components/pule/cartaoPule";
import { IPule } from "../../Interfaces/Pule";
import { useCallback, useMemo, useState } from "react";
import useColorScheme from "../../Components/aposta/colorScheme";
import useMask from "../../Components/useMask";
import { EStatus, IJogo } from "../../Interfaces/Jogo";
import useTipoJogoController from "../../Controllers/TipoJogo";
import { ITipoJogo } from "../../Interfaces/TipoJogo";
import ModalConfirmacao from "../../Components/ModalConfirmacao";
import useJogoController from "../../Controllers/Jogo";
import { v4 as uuidv4 } from "uuid";
import JogoCard from "../../Components/jogo/jogoCard";
import usePagina from "../../Components/usePagina";

const ApostaLista = () => {
    const Aposta = useOutletContext<IUseAposta>();
    const { Listar: ListaTipoJogo } = useTipoJogoController({updateQueryKey: 'TipoJogo', enableQuery: true});
    const Colors = useColorScheme();
    const formatter = useMask();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selecionada, setSelecionada] = useState<Array<number[]>>([]);
    const [selecionadaTipoJogo, setSelecionadaTipoJogo] = useState<number>();
    const { ListarJogaveis } = useJogoController({
        updateQueryKey: 'Jogo',
        enableQuery: false,
        enableJogaveis: true
    });
    const {NavegaPagina} = usePagina();

    const Cartoes = useCallback((pule: IPule) => {
        return <CartaoPule
            Pule={pule}
            Jogo={pule.JOGO ?? {VLR_APOSTA: 15} as IJogo}
            MaxDigits={
                ListaTipoJogo.isFetched && ListaTipoJogo.isSuccess && ListaTipoJogo.data ?
                    (ListaTipoJogo.data.find(x => x.CD_TIPO_JOGO === pule.JOGO!.CD_TIPO_JOGO) as ITipoJogo).VLR_MAX.toString().length
                :
                    2
            }
            formatter={formatter}
            Colors={Colors}
            ModoExibicao="ListaAposta"
            RepeteAposta={{
                setShowModal: setShowModal,
                showModal: showModal,
                setSelecionada: setSelecionada,
                selecionada: selecionada,
                selecionadaTipoJogo: selecionadaTipoJogo, 
                setSelecionadaTipoJogo: setSelecionadaTipoJogo
            }}
        />
    }, [formatter, Colors, ListaTipoJogo.isFetched, ListaTipoJogo.isSuccess, ListaTipoJogo.data, showModal, selecionada, selecionadaTipoJogo]);

    const ListaJogaveis = useMemo<IJogo[]>(() => {
        if (ListarJogaveis.isFetched && ListarJogaveis.isSuccess && ListarJogaveis.data) {
            return ListarJogaveis.data.filter(jogo => jogo.STATUS === EStatus.Aberto).sort((a, b) => {
                return (b.CD_JOGO ?? 0) - (a.CD_JOGO ?? 0);
            });
        } else {
            return [{} as IJogo];
        };
    }, [ListarJogaveis]);

    if (Aposta.Controller.ListarUsuario.isFetching) {
        return (
            <Container className="verticalCenter">
                <div className="d-flex mt-3 align-content-center w-100 flex-wrap justify-content-center">
                    <div className="w-100 mt-3 d-flex justify-content-center">
                        <Spinner className="mt-3" variant="primary"  />
                    </div>
                    <h6 className="mt-3">Carregando suas apostas</h6>
                </div>
            </Container>
        )
    } else {
        const jogaveis : Array<any> = ListaJogaveis.filter(Item => Item.CD_TIPO_JOGO === selecionadaTipoJogo)

        return (
            <>
                <ModalConfirmacao
                    titulo={'Selecione o jogo que deseja repetir a aposta'}
                    show={showModal} 
                    onHide={setShowModal}
                    variant={"none"}
                    onSubmitHandler={() => {}}
                >
                    <Container className="verticalCenter">
                    {
                        jogaveis && jogaveis.length > 0 ?
                            jogaveis.map(Item => {
                                return (
                                    <JogoCard key={uuidv4()} Jogo={Item} onClick={ () => {
                                        localStorage.setItem(`carrinho${Item.CD_JOGO}_StateStorage`, JSON.stringify(selecionada));
                                        NavegaPagina('JogoApostaCadastro', ['cdJogo'], [String(Item.CD_JOGO)]);
                                    } } />
                                )
                            })
                        :
                            <div>Não há nenhum jogo em aberto para repetir suas apostas</div>
                    }
                    </Container>
                </ModalConfirmacao>

                <Container className="verticalCenter p-0">
                    {
                        Aposta.Datasource.ListaUsuario && Aposta.Datasource.ListaUsuario.length > 0 ? 
                            Aposta.Datasource.ListaUsuario.map(pule => {
                                return (
                                    <Col key={pule.CD_JOGO_PULE} md={6}>
                                        {Cartoes(pule)}
                                    </Col>
                                )
                            })
                        :
                            <div className="mt-3">Você ainda não fez nenhuma aposta.</div>
                    }         
                </Container>
            </>
        )
    }
}

export default ApostaLista;