import * as yup from 'yup';
import { CONVENIO, DT_ENTRADA, DT_SAIDA, NUMERO, PACIENTE, PENDENCIA } from './schemas';

export interface IDinamico {
    CD_DINAMICO?    : number;
    EXPIRACAO       : 'QA' | '' | '' | '' ;
}

export interface IProtocolo {
    CD_PROTOCOLO?   : number;
    NUMERO          : number;
    PACIENTE        : string;
    PENDENCIA       : string;
    CONVENIO        : string;
    DT_ENTRADA      : Date | string;
    DT_SAIDA        : Date | string;
    USUARIO         : string;
    DT_ENTREGA?     : Date | string;
    EXCLUIDO?       : boolean;
}
export interface IProtocolos extends Array<IProtocolo>{};

export const schemaProtocolo = yup.object().shape({   
    NUMERO      : NUMERO,    
    PACIENTE    : PACIENTE,      
    CONVENIO    : CONVENIO,      
    PENDENCIA   : PENDENCIA,      
    DT_ENTRADA  : DT_ENTRADA,      
    DT_SAIDA    : DT_SAIDA        
});

// GENERICS
export interface IReactTable_Data {
    id?: string;
    Header?: any;
    Cell?: any;
    accessor?: string;
};
export interface IReactTable_Grid extends Array<IReactTable_Data>{};

export interface IReactTable_InitState {
    hiddenColumns?: Array<any>;
    pageSize?: number;
};

export interface IGenericInformation {
    CHAVE: string;
    VALOR: any;
};
export interface IGenericInformations extends Array<IGenericInformation>{};

export enum EMode {
    Browse  = 'browse',
    Edit    = 'edit',
    Include = 'include'
};

export type DeepPartial<T> = {
    [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>> | undefined
    : T[P] extends ReadonlyArray<infer V>
    ? ReadonlyArray<DeepPartial<V>> | undefined
    : DeepPartial<T[P]> | undefined;
};

export interface IErro {
    error: string;
    object: any;
}