import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from "react-query";
import useAPI from "../Components/useAPI";
import { DeepPartial, IErro } from "../Interfaces";
import useControllers from "../Components/useControllers";
import { IAposta } from "../Interfaces/Aposta";
import { IPule } from "../Interfaces/Pule";

interface Props {
    updateQueryKey  : string;
    isArray?        : boolean;
    queryValue?     : string;
    enableQuery?    : boolean;
    enableCambista? : boolean;
}

const useApostaController = ({updateQueryKey, queryValue, isArray = true, enableQuery = false, enableCambista = false} : Props) : IUseApostaController => {
    const API = useAPI();
    const queryClient = useQueryClient();
    const { Update, Create, Delete } = useControllers();

    const primaryKey = 'CD_JOGO_PULE';
    const jogoKey = 'CD_JOGO'; // queryValue
    const endpoint = 'pule';

    const ListarUsuario = useQuery<IPule[]>(
        updateQueryKey!+'Usuario',
        (
            () => API.get(`${endpoint}/listarUsuario`, '', false) // Todas apostas do usuario
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 3,
            enabled: enableQuery, // Só será habilitado pelo enableQuery
            refetchInterval: 300000,
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
        }
    );  
    
    const ListarCambistaJogo = useQuery<IPule[]>(
        updateQueryKey!+'CambistaJogo',
        (
            () => API.get(`${endpoint}/listarCambistaJogo/${queryValue}`, '', false) // Apostas do usuario no jogo
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 3,
            enabled: !!queryValue && enableCambista, // Só será habilitado se passar um cd_jogo
            refetchInterval: 300000,
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
        }
    );  

    const ListarUsuarioJogo = useQuery<IPule[]>(
        updateQueryKey!+'UsuarioJogo',
        (
            () => API.get(`${endpoint}/listarUsuarioJogo/${queryValue}`, '', false) // Apostas do usuario no jogo
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 3,
            enabled: !!queryValue, // Só será habilitado se passar um cd_jogo
            refetchInterval: 300000,
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
        }
    );  

    const ListarJogo = useQuery<IAposta[]>(
        updateQueryKey!+'Jogo',
        (
            () => API.get(`${endpoint}/listarJogo/${queryValue}`, '', false) // Apostas do jogo
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 3,
            enabled: !!queryValue, // Só será habilitado se passar um cd_jogo
            refetchInterval: 300000,
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
        }
    );  

    const Alterar = useMutation(
        (data : DeepPartial<IPule>) => 
            API.put(`${endpoint}/alterar/${data[primaryKey]}`, data)
        , {
            onSuccess: (ret: DeepPartial<IPule>, data: DeepPartial<IPule>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IPule[]>(
                        updateQueryKey!+'CambistaJogo',
                        (oldData: any) => {
                            if (oldData) {
                                return Update(oldData, data, primaryKey, isArray);
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
    });

    const Deletar = useMutation(
        (data : DeepPartial<IPule>) => 
            API.del(`${endpoint}/deletar/${data[primaryKey]}`, data, true)
        , {
            onSuccess: (ret: DeepPartial<IPule>, data: DeepPartial<IPule>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IPule[]>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Delete(oldData, {...data});
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
    });

    const Cadastrar = useMutation(
        (data : DeepPartial<IAposta>) => 
            API.post(`${endpoint}/cadastrar`, data)
        , {
            // onSuccess: (ret: any, data: DeepPartial<IAposta>) => {
            //     if (!!updateQueryKey) {
            //         queryClient.setQueryData<IAposta[]>(
            //             updateQueryKey,
            //             (oldData: any) => {
            //                 if (oldData) {
            //                     return Create(oldData, ret);
            //                 }
            //                 return oldData;
            //             }
            //         );
            //     };
            // },
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
    });

    const Buscar = useMutation(
        (data : DeepPartial<IPule>) => 
            API.get(`${endpoint}/buscar/${data[primaryKey]}`, "", false)
        , {
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
    });

    return {
        Alterar             : Alterar,
        Cadastrar           : Cadastrar,
        Deletar             : Deletar,
        Buscar              : Buscar,
        ListarUsuario       : ListarUsuario,
        ListarUsuarioJogo   : ListarUsuarioJogo,
        ListarJogo          : ListarJogo,
        ListarCambistaJogo  : ListarCambistaJogo
    }
}

export interface IUseApostaController {
    Alterar             : UseMutationResult<DeepPartial<IPule>, any, DeepPartial<IPule>, unknown>;
    Cadastrar           : UseMutationResult<DeepPartial<IAposta>, any, DeepPartial<IAposta>, unknown>
    Deletar             : UseMutationResult<DeepPartial<IPule>, any, DeepPartial<IPule>, unknown>;
    Buscar              : UseMutationResult<IPule, any, DeepPartial<IPule>, unknown>;
    ListarUsuario       : UseQueryResult<IPule[], unknown>;
    ListarUsuarioJogo   : UseQueryResult<IPule[], unknown>;
    ListarCambistaJogo  : UseQueryResult<IPule[], unknown>;
    ListarJogo          : UseQueryResult<IAposta[], unknown>;
}

export default useApostaController;