import { useOutletContext } from "react-router-dom";
import { Button, Card, Col, Container, Form, InputGroup, Nav, Row, Tab } from "react-bootstrap";
import FormRodape from "../../../Components/formularios/FormRodape";
import { Formik } from "formik";
import { useState } from "react";
import { EMode } from "../../../Interfaces";
import BadgeValidacao from "../../../Components/BadgeValidacao";
import { every } from "lodash";
import { IJogo, schemaJogo } from "../../../Interfaces/Jogo";
import { IUseJogo } from "../Hook/useJogo";
import useMask from "../../../Components/useMask";
import { JogoStatus } from "../../../Constants/jogo";
import JogoCadastroComissao from "./comissao";

const JogoCadastro = () => {
    const Jogo = useOutletContext<IUseJogo>();
    const formatter = useMask();
    const [confirmado, setConfirmado] = useState<boolean>(false);
    const [aba, setAba] = useState<'comissao' |'detalhes'>('comissao');

    return (
        <Container className="verticalCenter">
            <Formik
                enableReinitialize
                validationSchema={schemaJogo}
                onSubmit={(dados : IJogo) => {
                    if (!Jogo.Validacao.Comissao(dados)) {
                        if (Jogo.Modo === EMode.Edit) {
                            Jogo.Controller.Alterar.mutateAsync(dados, {
                                onSuccess: () => {
                                    Jogo.Navegar('0');
                                }
                            });
                        } else if (Jogo.Modo === EMode.Include) {
                            Jogo.Controller.Cadastrar.mutateAsync(dados, {
                                onSuccess: () => {
                                    Jogo.Navegar('0');
                                }
                            });
                        }
                    } else {
                        alert('Total de comissões deve fechar em 100%. Favor verificar os percentuais de comissões.')
                    }
                }}
                initialValues={{
                    CD_JOGO             : Jogo.Datasource.Item.CD_JOGO      ?? undefined,
                    NOME                : Jogo.Datasource.Item.NOME         ?? "",
                    STATUS              : Jogo.Datasource.Item.STATUS       ?? "RA",
                    DT_CADASTRO         : Jogo.Datasource.Item.DT_CADASTRO  ?? "",
                    DT_ALTERACAO        : Jogo.Datasource.Item.DT_ALTERACAO ?? "",
                    DT_INICIO           : Jogo.Datasource.Item.DT_INICIO    ?? "",
                    DT_FIM              : Jogo.Datasource.Item.DT_FIM       ?? "",
                    VLR_APOSTA          : Jogo.Datasource.Item.VLR_APOSTA   ?? "",
                    OBSERVACAO          : Jogo.Datasource.Item.OBSERVACAO   ?? "",
                    CD_TIPO_JOGO        : Jogo.Datasource.Item.CD_TIPO_JOGO ?? "",
                    CD_PESSOA_CADASTRO  : Jogo.Datasource.Item.CD_PESSOA_CADASTRO ?? undefined,
                    COMISSAO            : Jogo.Datasource.Item.COMISSAO     ?? []
                }}
                >
                {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    errors,
                }) => (
                    <Form onSubmit={handleSubmit} onReset={() => Jogo.Navegar('0')}>       
                        <Form.Group className="mt-3">
                            <Form.Label>Nome do Jogo:</Form.Label>
                            <BadgeValidacao validacao={errors.NOME} confirmado={confirmado} />
                            <Form.Control 
                                autoComplete="off"
                                name={'NOME'}
                                type={'text'}
                                value={values.NOME ?? ""}
                                onChange={handleChange}
                                isInvalid={every([errors.NOME, confirmado])}
                                placeholder="Nome identificador do tipo de jogo..." 
                                size="lg"
                            />
                        </Form.Group> 

                        <Row>
                            <Col md={6}>
                                <Form.Group className="mt-3">
                                    <Form.Label>Status:</Form.Label>
                                    <BadgeValidacao validacao={errors.STATUS} confirmado={confirmado} />
                                    <Form.Select 
                                        name={'STATUS'}
                                        value={values.STATUS ?? "RA"}
                                        onChange={ e => {
                                            setFieldValue("STATUS", e.target.value);
                                        }}
                                        isInvalid={every([errors.STATUS, confirmado])}
                                        size="lg"
                                    >
                                        {JogoStatus.map((Item) => (
                                            <option key={Item.STATUS} value={Item.STATUS} disabled={!Jogo.Validacao.Status(Item.STATUS)}>
                                                {Item.DESCRICAO}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group className="mt-3">
                                    <Form.Label>Tipo Jogo:</Form.Label>
                                    <BadgeValidacao validacao={errors.CD_TIPO_JOGO} confirmado={confirmado} />
                                    <Form.Select 
                                        name={'CD_TIPO_JOGO'}
                                        value={values.CD_TIPO_JOGO ?? ""}
                                        onChange={ e => {
                                            setFieldValue("CD_TIPO_JOGO", e.target.value);
                                        }}
                                        isInvalid={every([errors.CD_TIPO_JOGO, confirmado])}
                                        size="lg"
                                    >
                                        <option key={''} value={''} disabled={true}>Selecione</option>
                                        {Jogo.ListaTipoJogo.isFetched && Jogo.ListaTipoJogo.isSuccess && Jogo.ListaTipoJogo.data && Jogo.ListaTipoJogo.data.map((TipoJogo) => (
                                            <option key={TipoJogo.CD_TIPO_JOGO} value={TipoJogo.CD_TIPO_JOGO}>
                                                {TipoJogo.NOME}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <Form.Group className="mt-3">
                                    <Form.Label>Valor Aposta:</Form.Label>
                                    <BadgeValidacao validacao={errors.VLR_APOSTA} confirmado={confirmado} />
                                    <InputGroup>
                                        <InputGroup.Text id="monetario">R$</InputGroup.Text>
                                        <Form.Control 
                                            name={'VLR_APOSTA'}
                                            type={'number'}
                                            min={0}
                                            step={0.5}
                                            value={values.VLR_APOSTA ?? ""}
                                            onBlur={() => setFieldValue("VLR_APOSTA", formatter.setMaskMoney(values.VLR_APOSTA ?? 0, "", "."))}
                                            onChange={handleChange}
                                            isInvalid={every([errors.VLR_APOSTA, confirmado])}
                                            placeholder="Valor para cada aposta..." 
                                            size="lg"
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group className="mt-3">
                                    <Form.Label>Data de Inicio:</Form.Label>
                                    <BadgeValidacao validacao={errors.DT_INICIO} confirmado={confirmado} />
                                    <Form.Control 
                                        name={'DT_INICIO'}
                                        type="date"
                                        value={values.DT_INICIO?.toString()}
                                        onChange={handleChange}
                                        isInvalid={every([errors.DT_INICIO, confirmado])}
                                        size="lg"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>                        

                        <Form.Group className="mt-3">
                            <Form.Label>Observacao:</Form.Label>
                            <BadgeValidacao validacao={errors.OBSERVACAO} confirmado={confirmado} />
                            <Form.Control 
                                name={'OBSERVACAO'}
                                type={'text'}
                                as={'textarea'}
                                value={values.OBSERVACAO ?? ""}
                                onChange={handleChange}
                                isInvalid={every([errors.OBSERVACAO, confirmado])}
                                placeholder="Observações gerais do jogo..." 
                                size="lg"
                            />
                        </Form.Group>     

                        <Tab.Container
                            activeKey={aba}
                            onSelect={(eventKey) => setAba(eventKey as any)} 
                        >
                            <Card className="card mt-3 mb-3">
                                <Card.Header>
                                    <Nav variant="tabs">
                                        <Nav.Item>
                                            <Nav.Link eventKey="comissao">Comissões & Prêmios</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="detalhes">Detalhes</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Card.Header>

                                <Card.Body className="d-flex flex-wrap justify-content-center">
                                    {
                                        aba === 'comissao' ?
                                            <JogoCadastroComissao />
                                        :
                                            <></>
                                    }
                                    
                                </Card.Body>
                            </Card>
                        </Tab.Container>

                        <FormRodape>
                            <Button variant="outline-danger" size="lg" onClick={() => {}} type="reset">Cancelar</Button>
                            <Button variant="success" size="lg" onClick={() => {setConfirmado(true)}} type="submit">Confirmar</Button>
                        </FormRodape>
                    </Form>
                )}
            </Formik>            
        </Container>
    )
}

export default JogoCadastro;