import * as yup from 'yup';
import { NOME, PRIORIDADE } from './schemas';
import { IPessoa } from './Pessoa';

export interface IRegra {
    CD_REGRA?       : number;
    REGRA?          : string;
    IDENTIFICACAO?  : string;
    DESCRICAO?      : string;
    NOME?           : string;
    VALOR?          : string;
    ACESSO?         : boolean;
}

export interface IControle {
    CD_CONTROLE?    : number;
    CONTROLE?       : string;
    NOME?           : string;
    PRIORIDADE      : number;
    REGRAS?         : IRegra[];
    PESSOAS?        : IPessoa[];
}

export const schemaControle = yup.object().shape({   
    NOME            : NOME,
    PRIORIDADE      : PRIORIDADE
});