import { EConcursoStatus } from "../Interfaces/Concurso";

export const ConcursoStatus = [
    {
        STATUS: EConcursoStatus.Rascunho,
        DESCRICAO: 'Rascunho'
    },
    {
        STATUS: EConcursoStatus.Finalizado,
        DESCRICAO: 'Finalizado'
    }
]