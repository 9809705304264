interface ColorScheme {
    Background: string;
    Text: string;
}

const useColorScheme = () => {
    const Primary = {
        Background: getComputedStyle(document.documentElement).getPropertyValue('--bs-primary'),
        Text: getComputedStyle(document.documentElement).getPropertyValue('--bs-light')
    };
    
    const Secondary = {
        Background: getComputedStyle(document.documentElement).getPropertyValue('--bs-secondary'),
        Text: getComputedStyle(document.documentElement).getPropertyValue('--bs-emphasis-color')
    };
    
    const Success = {
        Background: getComputedStyle(document.documentElement).getPropertyValue('--bs-success'),
        Text: getComputedStyle(document.documentElement).getPropertyValue('--bs-emphasis-color')
    };
    
    const Info = {
        Background: getComputedStyle(document.documentElement).getPropertyValue('--bs-info'),
        Text: getComputedStyle(document.documentElement).getPropertyValue('--bs-emphasis-color')
    };
    
    const Warning = {
        Background: getComputedStyle(document.documentElement).getPropertyValue('--bs-warning'),
        Text: getComputedStyle(document.documentElement).getPropertyValue('--bs-emphasis-color')
    };
    
    const Danger = {
        Background: getComputedStyle(document.documentElement).getPropertyValue('--bs-danger'),
        Text: getComputedStyle(document.documentElement).getPropertyValue('--bs-emphasis-color')
    };
    
    const Light = {
        Background: getComputedStyle(document.documentElement).getPropertyValue('--bs-light'),
        Text: getComputedStyle(document.documentElement).getPropertyValue('--bs-emphasis-color')
    };
    
    const Dark = {
        Background: getComputedStyle(document.documentElement).getPropertyValue('--bs-dark'),
        Text: getComputedStyle(document.documentElement).getPropertyValue('--bs-emphasis-color')
    };

    const Gray = {
        Background: getComputedStyle(document.documentElement).getPropertyValue('--bs-gray-300'),
        Text: getComputedStyle(document.documentElement).getPropertyValue('--bs-primary-text-emphasis')
    }

    return {
        Primary,
        Secondary,
        Success,
        Info,
        Warning,
        Danger,
        Light,
        Dark,
        Gray
    }
}

export interface IUseColorScheme {
    Primary     : ColorScheme;
    Secondary   : ColorScheme;
    Success     : ColorScheme;
    Info        : ColorScheme;
    Warning     : ColorScheme;
    Danger      : ColorScheme;
    Light       : ColorScheme;
    Dark        : ColorScheme;
    Gray        : ColorScheme;
}

export default useColorScheme;