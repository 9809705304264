import { Button } from "react-bootstrap";
import { EPuleStatus, IPule } from "../../Interfaces/Pule";
import { IJogo } from "../../Interfaces/Jogo";
import { IUseJogo } from "../../Pages/Jogo/Hook/useJogo";
import { useOutletContext } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import PDFComprovantePule from "../../Reports/comprovantePule";

interface Props extends React.HTMLProps<HTMLDivElement> {
    Pule: IPule;
    Jogo: IJogo;
    formatter: any;
    MaxDigits: number;
}

const CartaoPuleConfirmacao = ({ Pule, Jogo, formatter, MaxDigits, ...rest }: Props) => {
    const JogoContexto = useOutletContext<IUseJogo>();

    return (
        <div className="w-100 d-flex flex-wrap justify-content-center">
            <Button
                variant="success" 
                disabled={Pule.STATUS === EPuleStatus.Finalizado}
                onClick={() => {
                    JogoContexto.Aposta.Controller.Alterar.mutateAsync({
                        CD_JOGO_PULE: Pule.CD_JOGO_PULE,
                        CD_JOGO     : Pule.CD_JOGO,
                        CD_APOSTADOR: Pule.CD_APOSTADOR,
                        CD_CAMBISTA : -1,
                        STATUS      : EPuleStatus.Finalizado
                    }, {
                        onSuccess: () => {
                            if (window.confirm('Pule confirmada com sucesso. Deseja imprimir um comprovante?')) {
                                PDFComprovantePule({Jogo: Jogo, Pule: Pule, MaxDigits: MaxDigits, formatter: formatter})
                            }

                            if (JogoContexto.Aposta.Controller.Buscar.isSuccess) {
                                JogoContexto.Aposta.Controller.Buscar.reset();
                                JogoContexto.Aposta.Controller.ListarCambistaJogo.refetch();
                            }
                        }
                    });
                }}
            >
                <FaCheck /> Confirmar Aposta
            </Button>
        </div>
    )
}

export default CartaoPuleConfirmacao;