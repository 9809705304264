import { useLayoutEffect, useMemo } from "react";
import useControleController, { IUseControleController } from "../../../Controllers/Controle";
import { EMode } from "../../../Interfaces";
import useControleDatasource, { IUseControleDatasource } from "./datasource";
import useControleGrid, { IUseControleGrid } from "./grid";
import usePagina from "../../../Components/usePagina";

const useControle = (cdControle?: string) : IUseControle => {
    const Codigo = cdControle;
    const {NavegaPagina} = usePagina();
    const Controller = useControleController({updateQueryKey: 'Controle'});
    const Datasource = useControleDatasource({Controller: Controller});
    const Grid = useControleGrid({Controller: Controller});

    useLayoutEffect(() => {
        if (Codigo && Number(Codigo) > 0) {
            Controller.Buscar.mutate({CD_CONTROLE: Number(Codigo)});
        } else {
            Controller.Buscar.reset();
        }
    }, [Codigo]); //eslint-disable-line

    const Modo : EMode = useMemo(() => {
        switch (Codigo) {
            case '0':
                return EMode.Browse;
            case '-1':
                return EMode.Include;
            default:
                return EMode.Edit;
        }
    }, [Codigo]);

    const Navegar = (CodigoControle: string) => {
        if (CodigoControle === '0') {
            NavegaPagina('Controle', [], []);
        } else {
            NavegaPagina('ControleCadastro', ['cdControle'], [CodigoControle]);
        }
    }

    return {
        Datasource  : Datasource,
        Grid        : Grid,
        Modo        : Modo,
        Navegar     : Navegar,
        Controller  : Controller
    }
};

export interface IUseControle {
    Datasource : IUseControleDatasource;
    Grid       : IUseControleGrid;
    Modo       : EMode;
    Navegar    : (CodigoControle: string) => void;
    Controller : IUseControleController;
}

export default useControle;