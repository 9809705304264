import { useContext, useLayoutEffect, useState } from "react";
import FormRodape from "../../../Components/formularios/FormRodape";
import useMask from "../../../Components/useMask";
import { Button, Card, Col, Container } from "react-bootstrap";
import { FaShoppingCart } from "react-icons/fa";
import CartaoDezena from "../../../Components/aposta/cartaoDezena";
import CartaoAposta from "../../../Components/aposta/cartaoAposta";
import useStateStorage from "../../../Components/useStateStorage";
import { useOutletContext } from "react-router-dom";
import { IUseJogo } from "../Hook/useJogo";
import ModalConfirmacao from "../../../Components/ModalConfirmacao";
import { CtxAppParams } from "../../../Contexts/ctxAppParams";
import usePessoaController from "../../../Controllers/Pessoa";
import { IPessoa } from "../../../Interfaces/Pessoa";
import { EPermissao } from "../../../Constants/permissao";

const JogoAposta = () => {
    const Jogo = useOutletContext<IUseJogo>();
    const formatter = useMask();
    const [carregamento, setCarregamento] = useState<boolean>(true);
    const [exibeCarrinho, setExibeCarrinho] = useState<boolean>();
    const [selecionado, setSelecionado] = useState<Array<number>>([]);
    const Carrinho = useStateStorage<Array<number[]> | undefined>(`carrinho${Jogo.Codigo}`, []);
    const AppParams = useContext(CtxAppParams);
    const { Buscar } = usePessoaController('Pessoa', true, false);

    useLayoutEffect(() => {
        if (Jogo.ListaTipoJogo.isFetched && Jogo.ListaTipoJogo.isSuccess && Jogo.ListaTipoJogo.data && Jogo.TipoJogo !== undefined) {
            setCarregamento(false);
        } else if (Jogo.ListaTipoJogo.isError) {
            throw 'Erro ao carregar TipoJogo: ' + Jogo.ListaTipoJogo.error // eslint-disable-line
        }
    }, [Jogo.ListaTipoJogo.data, Jogo.ListaTipoJogo.isFetched, Jogo.ListaTipoJogo.isSuccess, Jogo.TipoJogo, Jogo.ListaTipoJogo.isError, Jogo.ListaTipoJogo.error]);

    if (!carregamento) {
        return (
            <Container className={"verticalCenter"}>
                <ModalConfirmacao
                    titulo={'Finalizando compra de aposta'}
                    show={exibeCarrinho} 
                    onHide={setExibeCarrinho}
                    variant={"primary"}
                    onSubmitHandler={() => {
                        if (AppParams.Permissoes?.find(permissao => permissao.REGRA === EPermissao.ALTERA_PULE)) {
                            const celularInformado = window.prompt(`Digite o celular da cliente apostador:`);
                            if (celularInformado?.length === 11) {
                                Buscar.mutateAsync({CELULAR: celularInformado}, {
                                    onSuccess: (ret: IPessoa) => {
                                        if (window.confirm(`O celular digitado pertence a: ${ret.NOME} \n Celular: ${formatter.setMaskPhone(ret.CELULAR)} \n Deseja confirmar compra da aposta?`)) {
                                            Jogo.Aposta.Controller.Cadastrar.mutateAsync({
                                                CD_JOGO: Jogo.Datasource.Item.CD_JOGO,
                                                CD_APOSTADOR: ret.CD_PESSOA,
                                                CD_CAMBISTA: -1,
                                                APOSTA: Carrinho.Read!.map(aposta => ({LANCAMENTO: aposta}))
                                            }, {
                                                onSuccess: (retAposta) => {
                                                    alert(`Aposta cadastrada com sucesso, o número da sua pule para identificação é: ${retAposta.CD_JOGO_PULE}`)
                                                    Carrinho.Write([]);
                                                    setExibeCarrinho(false);
                                                    Jogo.Aposta.Controller.ListarCambistaJogo.refetch();
                                                }
                                            })
                                        }
                                    }
                                })
                            } else {
                                alert('Celular informado inválido.');
                            }
                        } else {
                            Jogo.Aposta.Controller.Cadastrar.mutateAsync({
                                CD_JOGO: Jogo.Datasource.Item.CD_JOGO,
                                CD_APOSTADOR: -1,
                                CD_CAMBISTA: null,
                                APOSTA: Carrinho.Read!.map(aposta => ({LANCAMENTO: aposta}))
                            }, {
                                onSuccess: (retAposta) => {
                                    Jogo.Aposta.Controller.ListarUsuarioJogo.refetch();
                                    alert(`Aposta cadastrada com sucesso, o número da sua pule para identificação é: ${retAposta.CD_JOGO_PULE}`);
                                    Carrinho.Write([]);
                                    setExibeCarrinho(false);
                                }
                            })
                        }
                    }}
                >
                    <div>
                        {`Deseja confirmar a compra de ${Carrinho.Read && Carrinho.Read.length ? Carrinho.Read.length : 0} Aposta(s) no valor total de ${formatter.setMaskMoney(Carrinho.Read && Carrinho.Read.length ? Carrinho.Read.length * Jogo.Datasource.Item.VLR_APOSTA : 0 , "R$")}?
                        Seus jogos ficarão pendentes até a confirmação do pagamento.`}
                    </div>
                </ModalConfirmacao>

                <Col lg={8}>
                    <div className="w-100 py-3 px-2 d-flex flex-wrap">
                        <div className="w-100 d-flex">
                            <h3>Preencha sua aposta</h3>
                        </div>

                        <div className="w-100 d-flex">
                            <CartaoDezena 
                                VlrMin={Jogo.TipoJogo.VLR_MIN} 
                                VlrMax={Jogo.TipoJogo.VLR_MAX}
                                QtdMin={Jogo.TipoJogo.QTD_MIN} 
                                QtdMax={Jogo.TipoJogo.QTD_MAX} 
                                Selecionado={selecionado}
                                setSelecionado={setSelecionado}
                                Carrinho={Carrinho.Read}
                                setCarrinho={Carrinho.Write}                            
                            />
                        </div>

                        
                    </div>
                </Col>
                <Col lg={4}>
                    <Card className="card my-3 mx-2 w-100">
                        <Card.Header>
                            Apostas no carrinho
                        </Card.Header>

                        <Card.Body className="d-flex flex-wrap justify-content-center">
                            <CartaoAposta
                                VlrMax={Jogo.TipoJogo.VLR_MAX}
                                Carrinho={Carrinho.Read}
                                setCarrinho={Carrinho.Write}     
                            />
                        </Card.Body>
                    </Card>
                </Col>
                <FormRodape>
                    <div className="container px-3 pt-2" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridTemplateRows: 'auto', gap: '0.1rem' }}>
                        <div className="total" style={{ gridRow: '1', gridColumn: '1/2' }}>
                            <p style={{ margin: "0", textAlign: 'left', fontSize: '1.1rem', fontWeight: 'bold' }}>
                                {`${formatter.setMaskMoney(Carrinho.Read && Carrinho.Read.length ? Carrinho.Read.length * Jogo.Datasource.Item.VLR_APOSTA : 0 , "R$")}`}
                            </p>
                            <p style={{ margin: "0", textAlign: 'left', fontSize: '1.1rem', fontWeight: 'normal' }}>
                                {`${Carrinho.Read && Carrinho.Read.length ? Carrinho.Read.length : 0} Aposta(s)`}
                            </p>
                        </div>
                        <div className="totalvalue" style={{ gridRow: '1', gridColumn: '3/3' }}>
                            <Button size="lg" variant="success" disabled={Carrinho.Read?.length === 0} onClick={() => setExibeCarrinho(true)}>
                                <FaShoppingCart/> Comprar
                            </Button>
                        </div>
                    </div>
                </FormRodape>
            </Container>
        );
    } else {
        return (
            <>carregando</>
        )
    }
}

export default JogoAposta;