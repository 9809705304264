import { useOutletContext } from "react-router-dom"
import Tabela from "../../Components/tabela";
import { Button, Container } from "react-bootstrap";
import FormRodape from "../../Components/formularios/FormRodape";
import { TiPlus } from "react-icons/ti";
import { IUseTipoJogo } from "./Hook/useTipoJogo";
import { ITipoJogo } from "../../Interfaces/TipoJogo";

const TipoJogoLista = () => {
    const TipoJogo = useOutletContext<IUseTipoJogo>();

    return (
        <Container className="verticalCenter">
            <Tabela colunas={TipoJogo.Grid.Lista} dados={TipoJogo.Datasource.Lista} handleClick={(e : ITipoJogo) => TipoJogo.Navegar(String(e.CD_TIPO_JOGO))} />

            <FormRodape>
                <Button variant="success" size="lg" onClick={() => TipoJogo.Navegar('-1')}><TiPlus /> Cadastrar</Button>
            </FormRodape>
        </Container>        
    )
}

export default TipoJogoLista;