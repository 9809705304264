import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from "react-query";
import useAPI from "../Components/useAPI";
import { DeepPartial, IErro } from "../Interfaces";
import { IControle } from "../Interfaces/Controle";
import useControllers from "../Components/useControllers";

interface Props {
    updateQueryKey  : string;
    isArray?        : boolean;
    enableQuery?    : boolean;
}

const useControleController = ({updateQueryKey, isArray = true, enableQuery = true} : Props) : IUseControleController => {
    const API = useAPI();
    const queryClient = useQueryClient();
    const { Update, Create, Delete } = useControllers();

    const primaryKey = 'CD_CONTROLE';
    const endpoint = 'controle';

    const Listar = useQuery<IControle[]>(
        updateQueryKey!,
        (
            () => API.get(`${endpoint}/listar/`, '', false)
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 3,
            enabled: enableQuery,
            refetchInterval: 600000,
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
        }
    );  

    const Alterar = useMutation(
        (data : DeepPartial<IControle>) => 
            API.put(`${endpoint}/alterar/${data[primaryKey]}`, data)
        , {
            onSuccess: (ret: DeepPartial<IControle>, data: DeepPartial<IControle>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IControle[]>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Update(oldData, {...data, CONTROLE: data.NOME}, primaryKey, isArray);
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
    });

    const Deletar = useMutation(
        (data : DeepPartial<IControle>) => 
            API.del(`${endpoint}/deletar/${data[primaryKey]}`, data, true)
        , {
            onSuccess: (ret: DeepPartial<IControle>, data: DeepPartial<IControle>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IControle[]>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Delete(oldData, {...data});
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
    });

    const Cadastrar = useMutation(
        (data : DeepPartial<IControle>) => 
            API.post(`${endpoint}/cadastrar`, data)
        , {
            onSuccess: (ret: any, data: DeepPartial<IControle>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IControle[]>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Create(oldData, {...ret, CONTROLE: data.NOME});
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
    });

    const Buscar = useMutation(
        (data : DeepPartial<IControle>) => 
            API.get(`${endpoint}/buscar/${data[primaryKey]}`, "")
        , {
            onSuccess: (ret: IControle) => {
                // setItem(ret);
            },
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
    });

    return {
        Alterar     : Alterar,
        Cadastrar   : Cadastrar,
        Listar      : Listar,
        Deletar     : Deletar,
        Buscar      : Buscar
    }
}

export interface IUseControleController {
    Alterar     : UseMutationResult<DeepPartial<IControle>, any, DeepPartial<IControle>, unknown>;
    Cadastrar   : UseMutationResult<DeepPartial<IControle>, any, DeepPartial<IControle>, unknown>
    Listar      : UseQueryResult<IControle[], unknown>;
    Deletar     : UseMutationResult<DeepPartial<IControle>, any, DeepPartial<IControle>, unknown>;
    Buscar      : UseMutationResult<IControle, any, DeepPartial<IControle>, unknown>;
}

export default useControleController;