import usePaginaProvider from "./Components/usePaginaProvider";
import { ICtxAppParams } from "./Contexts/ctxAppParams";

interface Props {
    AppParams: ICtxAppParams
}

const PageProvider = ({AppParams} : Props) => {
    usePaginaProvider(AppParams);
    
    return (
        <></>
    )
}

export default PageProvider;