import { useContext } from "react";
import { IPagina, Paginas } from "../Constants/paginas"
import { CtxAppParams } from "../Contexts/ctxAppParams";
import { useNavigate } from "react-router-dom";

const usePagina = () : IUsePagina => {
    const AppParams = useContext(CtxAppParams);
    const navigate = useNavigate();

    const NavegaPagina = (Alias: string, paramNames: string[], paramValues: (string | number)[]) : void => {
        function findPageByAlias(pages: IPagina[], alias: string): IPagina | null {
            for (const page of pages) {
                if (page.Alias === alias) {
                    return page;
                }
                const subPage = findPageByAlias(page.Pages, alias);
                if (subPage) {
                    return subPage;
                }
            }
            return null;
        }
    
        function constructPath(path: string, paramNames: string[], paramValues: (string | number)[]): string {
            // let path = page.Path;

            paramNames.forEach((param, index) => {
                const value = String(paramValues[index]);
                path = path.replace(`:${param}?`, value).replace(`:${param}`, value);
            });
            // Remove parametros
            path = path.replace(/\/:[^\/]+\?/g, ''); //eslint-disable-line
            return path;
        }
    
        function getParentPath(pages: IPagina[], targetAlias: string, currentPath = ''): string {
            for (const page of pages) {
                const newPath = `${currentPath}/${page.Path}`.replace(/\/+/g, '/');
                if (page.Alias === targetAlias) {
                    return currentPath;
                }
                for (const subPage of page.Pages) {
                    const result = getParentPath([subPage], targetAlias, newPath);
                    if (result) {
                        return result;
                    }
                }
            }
            return '';
        }
    
        const page = findPageByAlias(Paginas, Alias);
        if (!page) {
            throw new Error(`Página indicada com o alias ${Alias} não foi encontrada`);
        }
    
        // const path = constructPath(page, paramNames, paramValues);
        const parentPath = getParentPath(Paginas, Alias);
        const fullPath = `${parentPath}/${page.Path}`.replace(/\/+/g, '/');
        const pathComplete = constructPath(fullPath, paramNames, paramValues);
        navigate(pathComplete.replace(/\/$/, '')); // Remove trailing slash if present
    }

    const RetornaPagina = (Alias: string) : IPagina | undefined => {
        const Elemento : IPagina | undefined = Paginas.find(pagina => pagina.Alias === Alias);

        if (Elemento && 
            ((Elemento.Permissions === undefined || Elemento?.Permissions === null || Elemento.Permissions.length === 0) || // Sem permissao definida ou
            Elemento.Permissions?.every(permissao => AppParams.Permissoes?.some(pessoa => pessoa.REGRA === permissao))) // Todas as permissões atendidas
        ) {
            return (Elemento)
        } else {
            return undefined
        }
    }

    return {
        RetornaPagina,
        NavegaPagina
    }
}

export interface IUsePagina {
    RetornaPagina: (Alias: string) => IPagina | undefined;
    NavegaPagina : (Alias: string, paramNames: string[], paramValues: (string | number)[]) => void;
}

export default usePagina;