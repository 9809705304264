import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Content, TDocumentDefinitions } from "pdfmake/interfaces";
import { IPule } from "../Interfaces/Pule";
import { IJogo } from "../Interfaces/Jogo";
import { IAposta } from "../Interfaces/Aposta";

interface Props {
    Jogo: IJogo;
    Pule: IPule;
    MaxDigits: number;
    formatter: any;
}

const PDFComprovantePule = ({Jogo, Pule, MaxDigits, formatter} : Props) => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const title     : Content = [
        {
            text: [
                {text: `${'Dezeninha'}\n`, fontSize: 15, bold: true}
            ],            
            alignment: 'center',
            margin: [15, 20, 15, 0],
        }
    ];

    const qrCode : Content = [
        {
            columns: [
                {
                    qr: `${process.env.REACT_APP_URL}/detalhe/${Jogo.CD_JOGO}/apostaLista`,
                    fit: 80,
                    alignment: 'right',
                    margin: [0, 0, 5, 0],
                },
                {
                    text: [
                        {text: `${Jogo.NOME}\n`, fontSize: 9, color: 'blue', decoration: 'underline', link: `${process.env.REACT_APP_URL}/detalhe/${Jogo.CD_JOGO}/apostaLista`, bold: true},
                        {text: `Acompanhe suas apostas através do QrCode!`, fontSize: 8}
                    ],
                    alignment: 'left',
                    margin: [5, 0, 0, 0],
                }
            ],
        }
        
    ]

    const footer    : Content = [
        {
            text: `${Jogo.OBSERVACAO}`,
            alignment: 'center',
            fontSize: 8
        }
    ]

    const dados   : Content = [
        {
            text: `
            Pule: ${Pule.CD_JOGO_PULE}`,
            fontSize: 10,
            bold: true,
            alignment: 'center',
        },
        {
            text: Jogo.DT_INICIO ? `Data Prevista de Início: ${formatter.setDate(new Date(Jogo.DT_INICIO), {dateStyle: "medium"}, false)}` : '',
            fontSize: 9,
            bold: true,
            alignment: 'center',
            margin: [0, 0, 0, 10],
        },
        {
            columns: [
                {
                    text: `Apostador: ${Pule.NOME}
                        Qtd. Apostas: ${Pule.APOSTA?.length ?? 0}`,
                    fontSize: 9,
                    alignment: 'left'
                },
                {
                    text: `
                    Vlr. Total: ${formatter.setMaskMoney((Pule.APOSTA?.length ?? 0) * (Jogo.VLR_APOSTA) ?? 0, 'R$')}`,
                    fontSize: 9,
                    alignment: 'right'
                }
            ],
        },
        
    ];

    const dadosJogos = Pule.APOSTA && Pule.APOSTA.map((aposta: IAposta, index) : Content => {
        return [
            {
                margin: [0, 10, 0, 0],
                table: {
                    headerRows: 1,
                    widths: aposta.LANCAMENTO!.map(x => '*'),
                    body: [
                        [
                            // Cabeçalho que cobre todas as colunas com base nos lançamentos
                            { 
                                columns: [
                                    { text: `Aposta ${aposta.CD_JOGO_PULE_APOSTA}`, alignment: 'left' }, 
                                    { text: `#${index + 1}`, alignment: 'right' }
                                ], 
                                style: 'tableHeader', 
                                fontSize: 8, 
                                colSpan: aposta.LANCAMENTO!.length, // ColSpan cobre todas as colunas
                                bold: true 
                            },
                            ...Array(aposta.LANCAMENTO!.length - 1).fill({}) // Preenche as colunas restantes do cabeçalho
                        ],
                        // [ 
                        //     {columns: [{text: `Aposta ${aposta.CD_JOGO_PULE_APOSTA}`, alignment: 'left'}, {text: `#${index+1}`, alignment: 'right'}], style: 'tableHeader', fontSize: 8, colSpan: 10, bold: true},
                        //     {},
                        //     {},
                        //     {},
                        //     {},
                        //     {},
                        //     {},
                        //     {},
                        //     {},
                        //     {},
                        // ],
                        aposta.LANCAMENTO!.map(lancamento => {
                            return {
                                text: lancamento.NUMERO.toString().padStart(MaxDigits, '0'), fontSize: 8, colSpan: 1, rowSpan: 1, alignment: 'center'
                            }
                        })
                    ]
                },
                layout: { fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex % 2 === 0) ? '#CCCCCC' : null;
                }}
            }
        ]
    });

    const semDados : Content = [
        {
            text: ``,
            alignment: 'center',
            fontSize: 8
        }
    ];

    // const details   : Content = [
    //     {
    //         table: {
    //             headerRows: 2,
    //             widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
    //             body: [
    //                 [ 
    //                     {text: 'Jogador', style: 'tableHeader', fontSize: 8, colSpan: 10, bold: true},
    //                     {},
    //                     {},
    //                     {},
    //                     {},
    //                     {},
    //                     {},
    //                     {},
    //                     {},
    //                     {},
    //                 ],
    //                 [ 
    //                     {text: 'Nº 1', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 2', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 3', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 4', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 5', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 6', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 7', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 8', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 9', style: 'tableHeader', fontSize: 8, bold: true},
    //                     {text: 'Nº 10', style: 'tableHeader', fontSize: 8, bold: true}
    //                 ],
    //                 ...dadosJogos
    //             ]
    //         },
    //         layout: { fillColor: function (rowIndex, node, columnIndex) {
    //             return (rowIndex % 2 === 1 && rowIndex > 0) ? '#CCCCCC' : null;
    //         }}
    //     },
    // ];

    // const footer = (currentPage: number , pageCount: number) : Content => {
    //     return [
    //         {
    //             text: currentPage.toString() + ' / ' + pageCount.toString(),
    //             alignment: 'center',
    //             fontSize: 9,
    //             margin: [0, 10, 20, 0],
    //         }
    //     ]
    // }

    const docDefinitions : TDocumentDefinitions = {
        pageSize: {width: 226, height: 240 + ((Pule.APOSTA && Pule.APOSTA.length * 42) ?? 42)},
        pageMargins: [10, 50 , 10 , 50], //left top right bottom

        header: [title],
        content: [qrCode, dados, dadosJogos ?? semDados],
        footer: [footer]
    }

    pdfMake.createPdf(docDefinitions).open();
}

export default PDFComprovantePule;