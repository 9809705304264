import { UseQueryResult, useQuery } from "react-query";
import useAPI from "../Components/useAPI";
import { IErro } from "../Interfaces";
import { IRegra } from "../Interfaces/Controle";

interface Props {
    updateQueryKey  : string;
    isArray?        : boolean;
    enableQuery?    : boolean;
}

const useRegraController = ({updateQueryKey, isArray = true, enableQuery = true} : Props) : IUseRegraController => {
    const API = useAPI();
    const endpoint = 'regra';

    const Listar = useQuery<IRegra[]>(
        updateQueryKey!,
        (
            () => API.get(`${endpoint}/listar/`, '', false)
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 3,
            enabled: enableQuery,
            refetchInterval: 600000,
            onError: (error: any) => {
                try {
                    const erro : IErro = JSON.parse(error)
                    alert(erro.error)
                } catch {
                    alert(error)
                }
            }
        }
    ); 

    return {
        Listar      : Listar
    }
}

export interface IUseRegraController {
    Listar      : UseQueryResult<IRegra[], unknown>;
}

export default useRegraController;