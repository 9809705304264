import { Routes, BrowserRouter, Route } from "react-router-dom";
import { useContext } from "react";

import { CtxAppParams } from "./Contexts/ctxAppParams";
import Autenticacao from "./Pages/Autenticacao/Autenticacao";
import usePermission from "./Components/usePermission";
import { IPagina, Paginas } from "./Constants/paginas";
import Header from "./Components/header";
import PageProvider from "./pageProvider";

const Rotas = () => {
    const AppParams = useContext(CtxAppParams);
    usePermission(AppParams);

    const mapRoutes = (pages: IPagina[]): JSX.Element[] => {
        return pages.map((page) => {
            return (
                <Route key={page.Id} path={page.Path} element={page.Element}>
                    {page.Index && <Route index element={page.Index} />}
                    {page.Pages.length > 0 && mapRoutes(page.Pages)}
                </Route>
            );
        });
    };

    if(!AppParams.Token!) {
        return (
            <Autenticacao />
        )
    } else {
        return (

            <BrowserRouter>            
                <div style={{marginBottom: '9vh'}}>
                    <Header />
                    <PageProvider AppParams={AppParams} />
                    <Routes>
                        {
                            mapRoutes(Paginas)
                        }
                    </Routes>
                </div>
            </BrowserRouter>
        )
    }
}

export default Rotas;