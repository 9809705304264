import { Outlet, useParams } from "react-router-dom";
import useConcurso from "./Hook/useConcurso";

const Concurso = () => {
    const { cdConcurso } = useParams();
    const Concurso = useConcurso({CodigoConcurso: cdConcurso});

    return (
        <Outlet context={Concurso} />
    );
}

export default Concurso;