import { useMemo } from "react";
import { IUseTipoJogoController } from "../../../Controllers/TipoJogo";
import { ITipoJogo } from "../../../Interfaces/TipoJogo";

interface Props {
    Controller: IUseTipoJogoController;
}

const useTipoJogoDatasource = ({Controller} : Props) : IUseTipoJogoDatasource => {
    const Lista = useMemo<ITipoJogo[]>(() => {
        if (Controller.Listar.isFetched && Controller.Listar.isSuccess && Controller.Listar.data) {
            return Controller.Listar.data;
        } else {
            return [{} as ITipoJogo];
        };
    }, [Controller.Listar]);

    const Item = useMemo<ITipoJogo>(() => {
        if (Controller.Buscar.isSuccess && Controller.Buscar.data) {
            return Controller.Buscar.data;
        } else {
            return {} as ITipoJogo;
        };
    }, [Controller.Buscar.isSuccess, Controller.Buscar.data]);

    return {
        Lista,
        Item
    }
}

export interface IUseTipoJogoDatasource {
    Lista   : ITipoJogo[];
    Item    : ITipoJogo;
}

export default useTipoJogoDatasource;