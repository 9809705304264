interface Props extends React.HTMLProps<HTMLDivElement> {
    Color: Color;
    Number: string;
    Scale: number;
}

interface Color {
    Background: string;
    Text: string;
}

const Dezena = ({Color, Number, Scale, ...rest}: Props) => {
    return (
        <div 
            className="d-flex justify-content-center align-items-center m-1 destacavel"
            style={{
                borderRadius: '1000rem', 
                backgroundColor: Color.Background,
                color: Color.Text,
                height: `${Scale}rem`, width: `${Scale}rem`,
                fontWeight: 'bold',
                cursor: 'pointer',
                userSelect: 'none'
            }}
            {...rest}
        >
            {Number}
        </div>
    )
}

export default Dezena;