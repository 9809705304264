import { useMemo } from "react";
import { IUseApostaController } from "../../../../Controllers/Aposta";
import { IAposta } from "../../../../Interfaces/Aposta";
import { IPule } from "../../../../Interfaces/Pule";


interface Props {
    Controller: IUseApostaController;
}

const useApostaDatasource = ({Controller} : Props) : IUseApostaDatasource => {
    const ListaJogo = useMemo<IAposta[]>(() => {
            if (Controller.ListarJogo.isFetched && Controller.ListarJogo.isSuccess && Controller.ListarJogo.data) {
                return Controller.ListarJogo.data.sort((a, b) => (b.PONTO ?? 0) - (a.PONTO ?? 0));
            } else {
                return [{} as IAposta];
            };
    }, [Controller.ListarJogo.data]); //eslint-disable-line

    const ListaUsuario = useMemo<IPule[]>(() => {
        if (Controller.ListarUsuario.isFetched && Controller.ListarUsuario.isSuccess && Controller.ListarUsuario.data) {
            // return Controller.ListarUsuario.data;
            return Controller.ListarUsuario.data.sort((a, b) => (b.CD_JOGO_PULE ?? 0) - (a.CD_JOGO_PULE ?? 0));
        } else {
            return [{} as IPule];
        };
    }, [Controller.ListarUsuario.data]); //eslint-disable-line

    const ListaUsuarioJogo = useMemo<IPule[]>(() => {
        if (Controller.ListarUsuarioJogo.isFetched && Controller.ListarUsuarioJogo.isSuccess && Controller.ListarUsuarioJogo.data) {
            // return Controller.ListarUsuarioJogo.data;
            return Controller.ListarUsuarioJogo.data.sort((a, b) => (b.CD_JOGO_PULE ?? 0) - (a.CD_JOGO_PULE ?? 0));
        } else {
            return [{} as IPule];
        };
    }, [Controller.ListarUsuarioJogo.data]); //eslint-disable-line
    
    const ListaCambistaJogo = useMemo<IPule[]>(() => {
        if (Controller.ListarCambistaJogo.isFetched && Controller.ListarCambistaJogo.isSuccess && Controller.ListarCambistaJogo.data) {
            // return Controller.ListarCambistaJogo.data;
            return Controller.ListarCambistaJogo.data.sort((a, b) => (b.CD_JOGO_PULE ?? 0) - (a.CD_JOGO_PULE ?? 0));
        } else {
            return [{} as IPule];
        };
    }, [Controller.ListarCambistaJogo.data]); //eslint-disable-line

    const Item = useMemo<IPule>(() => {
        if (Controller.Buscar.isSuccess && Controller.Buscar.data) {
            return Controller.Buscar.data;
        } else {
            return {} as IPule;
        };
    }, [Controller.Buscar.isSuccess, Controller.Buscar.data]);

    return {
        ListaJogo,
        ListaUsuario,
        ListaUsuarioJogo,
        ListaCambistaJogo,
        Item
    }
}

export interface IUseApostaDatasource {
    ListaJogo           : IAposta[];
    ListaUsuario        : IPule[];
    ListaUsuarioJogo    : IPule[];
    ListaCambistaJogo   : IPule[];
    Item                : IPule;
}

export default useApostaDatasource;