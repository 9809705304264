import { Outlet, useParams } from "react-router-dom";
import useTipoJogo from "./Hook/useTipoJogo";


const TipoJogo = () => {
    const { cdTipoJogo } = useParams();
    const TipoJogo = useTipoJogo(cdTipoJogo);

    return (
        <Outlet context={TipoJogo} />
    );
}

export default TipoJogo;