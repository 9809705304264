import { useLayoutEffect, useMemo } from "react";
import { EMode } from "../../../Interfaces";
import usePagina from "../../../Components/usePagina";
import useTipoJogoController, { IUseTipoJogoController } from "../../../Controllers/TipoJogo";
import useTipoJogoGrid, { IUseTipoJogoGrid } from "./grid";
import useTipoJogoDatasource, { IUseTipoJogoDatasource } from "./datasource";

const useTipoJogo = (cdTipoJogo?: string) : IUseTipoJogo => {
    const Codigo = cdTipoJogo;
    const {NavegaPagina} = usePagina();
    const Controller = useTipoJogoController({updateQueryKey: 'TipoJogo'});
    const Datasource = useTipoJogoDatasource({Controller: Controller});
    const Grid = useTipoJogoGrid({Controller: Controller});

    useLayoutEffect(() => {
        if (Codigo && Number(Codigo) > 0) {
            Controller.Buscar.mutate({CD_TIPO_JOGO: Number(Codigo)});
        } else {
            Controller.Buscar.reset();
        }
    }, [Codigo]); //eslint-disable-line

    const Modo : EMode = useMemo(() => {
        switch (Codigo) {
            case '0':
                return EMode.Browse;
            case '-1':
                return EMode.Include;
            default:
                return EMode.Edit;
        }
    }, [Codigo]);

    const Navegar = (CodigoTipoJogo: string) => {
        if (CodigoTipoJogo === '0') {
            NavegaPagina('TipoJogo', [], []);
        } else {
            NavegaPagina('TipoJogoCadastro', ['cdTipoJogo'], [CodigoTipoJogo]);
        }
    }

    return {
        Datasource  : Datasource,
        Grid        : Grid,
        Modo        : Modo,
        Navegar     : Navegar,
        Controller  : Controller
    }
};

export interface IUseTipoJogo {
    Datasource : IUseTipoJogoDatasource;
    Grid       : IUseTipoJogoGrid;
    Modo       : EMode;
    Navegar    : (CodigoControle: string) => void;
    Controller : IUseTipoJogoController;
}

export default useTipoJogo;