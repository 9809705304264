import { useCallback, useEffect } from "react";
import { IPagina, Paginas } from "../Constants/paginas";
import { useLocation } from "react-router-dom";
import { ICtxAppParams } from "../Contexts/ctxAppParams";

const Functions = () => {
    const matchPath = useCallback((pathname: string, path: string): boolean => {
        const pathParts = path.split('/').filter(Boolean);
        const pathnameParts = pathname.split('/').filter(Boolean);

        if (pathParts.length !== pathnameParts.length) {
            return false;
        }

        return pathParts.every((part, index) => {
            if (part.startsWith(':')) {
                const isOptional = part.endsWith('?');
                return isOptional || pathnameParts[index] !== undefined;
            }
            return part === pathnameParts[index];
        });
    }, []);

    const findPage = useCallback((pages: IPagina[], pathname: string, parentPath = ''): IPagina | null => {
        for (const page of pages) {
            const fullPath = `${parentPath}/${page.Path}`.replace(/\/+/g, '/');
            if (matchPath(pathname, fullPath)) {
                return page;
            }
            const subPage = findPage(page.Pages, pathname, fullPath);
            if (subPage) {
                return subPage;
            }
        }
        return null;
    }, [matchPath]);

    function validarPaginas(paginas: IPagina[], idPais: number[] = [], aliasSet: Set<string> = new Set()) {
        const erros: string[] = [];

        paginas.forEach((pagina, index) => {
        const idHistory = idPais.concat(index);
    
        if (pagina.Id !== index) {
            const mensagemErro = `Objeto de Pagina de Id ${pagina.Id} inválido, o Id esperado seria ${index} (${idHistory.join('.')})`;
            erros.push(`*${pagina.Alias}* (${mensagemErro});`);
        }
    
        if (aliasSet.has(pagina.Alias)) {
            erros.push(`*${pagina.Alias}* (Alias duplicado encontrado (${idHistory.join('.')}));`);
        } else {
            aliasSet.add(pagina.Alias);
        }
    
        if (pagina.Pages.length > 0) {
            const subErros = validarPaginas(pagina.Pages, idHistory, new Set(aliasSet));
            if (subErros.length > 0) {
            erros.push(...subErros);
            }
        }
        });
    
        return erros;
    }

    return {
        findPage,
        validarPaginas
    }
}

const usePaginaProvider = (AppParams: ICtxAppParams) => {
    const location = useLocation();
    const functions = Functions();

    useEffect(() => {
        const currentPage = functions.findPage(Paginas, location.pathname);
        if (currentPage) {
            AppParams.setPaginaAtual(currentPage);
        } else {
            AppParams.setPaginaAtual(undefined);
        }
    }, [location, functions.findPage]); //eslint-disable-line

    useEffect(() => { // Valida objeto de paginas
        const erros = functions.validarPaginas(Paginas);

        if (erros.length > 0) {
            throw new Error(`usePagina.tsx -> Paginas\n${erros.join('\n')}`);
        }
    });
}

export default usePaginaProvider;