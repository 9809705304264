import { useOutletContext } from "react-router-dom";
import { IUseJogo } from "../Hook/useJogo";
import { Col, Container, Spinner } from "react-bootstrap";
import { useCallback, useMemo } from "react";
import useColorScheme from "../../../Components/aposta/colorScheme";
import useMask from "../../../Components/useMask";
import CartaoPule from "../../../Components/pule/cartaoPule";
import { IPule } from "../../../Interfaces/Pule";

const JogoApostaUsuario = () => {
    const Jogo = useOutletContext<IUseJogo>();
    const Colors = useColorScheme();
    const formatter = useMask();

    const maxDigits = useMemo(() => {
        return Jogo.TipoJogo?.VLR_MAX.toString().length;
    }, [Jogo.TipoJogo?.VLR_MAX]);

    const Cartoes = useCallback((pule: IPule) => {
        return <CartaoPule
            Pule={pule}
            Jogo={Jogo.Datasource.Item}
            MaxDigits={maxDigits}
            formatter={formatter}
            Colors={Colors}
        />
    }, [Jogo.Datasource.Item, maxDigits, formatter, Colors]);

    if (Jogo.Aposta.Controller.ListarUsuarioJogo.isFetching) {
        return (
            <Container className="verticalCenter">
                <div className="d-flex mt-3 align-content-center w-100 flex-wrap justify-content-center">
                    <div className="w-100 mt-3 d-flex justify-content-center">
                        <Spinner className="mt-3" variant="primary"  />
                    </div>
                    <h6 className="mt-3">Carregando suas apostas</h6>
                </div>
            </Container>
        )
    } else {
    return (
        <Container className="verticalCenter p-0">
            {
                Jogo.Aposta.Datasource.ListaUsuarioJogo && Jogo.Aposta.Datasource.ListaUsuarioJogo.length > 0 ? 
                    Jogo.Aposta.Datasource.ListaUsuarioJogo.map(pule => {
                        return (
                            <Col key={pule.CD_JOGO_PULE} md={6}>
                                {Cartoes(pule)}
                            </Col>
                        )
                    })
                :
                    <div className="mt-3">Você ainda não fez nenhuma aposta nesse jogo.</div>
            }         
        </Container>
    )
    }
}

export default JogoApostaUsuario;